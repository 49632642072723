import dataProvider from "src/dataProvider";
import { Roles } from "src/types/api";

let fetcher = dataProvider("openApiInstance");

export const getRole = async () => {
  return fetcher.get<Roles[]>("/roles");
};

// not in use
export const getOrganizationType = async () => {
  return fetcher.get("/organization/type");
};

export const getStates = () => {
  return fetcher.get(`/projects/public/states`);
};
