import { Box } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type HeaderSectionProps = {
  title: string;
  description?: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const HeaderSection = ({
  title,
  description,
  customStyles,
}: HeaderSectionProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("headerContainer")}>
      <Box {...styles("sponserHalf")}>
        <Box component={"img"} src="/assets/images/groupImgHeader.png" />
        <Box {...styles("GCP")}>GCP</Box>
      </Box>
      <Box {...styles("heading")}>{title}</Box>
    </Box>
  );
};

export default HeaderSection;
