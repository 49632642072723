import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  TitleBoxContainer: {
    display: "flex",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
    gap: "20px",
    justifyContent: "space-between",
  },
  backBtnBox: {
    display: "flex",
    gap: "20px",
  },
  backBtn: {
    cursor: "pointer",
  },
  title: {
    color: "#001037",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    letterSpacing: "0.36px",
  },
  subTitle: {
    color: "rgba(0, 16, 55, 0.60)",
    fontSize: "14px",
    fontWeight: "400",
    letterSpacing: "0.28px",
  },

  root: {
    maxWidth: {
      sm: "none",
      xs: "180px",
    },
    fontSize: {
      sm: "18px",
      xs: "14px",
    },
  },
});

export default styles;
