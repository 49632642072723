import dataProvider from "src/dataProvider";
import { Login } from "src/types/api";

let fetcher = dataProvider("openApiInstance");

export const signIn = async (data: Login) => {
  return fetcher.post<Login>("/auth/login", data);
};

// not in use
export const signUp = async (data: any) => {
  return fetcher.post("/auth/sign-up", data);
};
