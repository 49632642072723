import { devTypes, ProjectType } from "src/constants/common";
import { CreatePorjectForm } from "src/types/form";

import { saveAs } from "file-saver";

import * as XLSX from "xlsx";
import * as XLSXS from "xlsx-style";
import ExcelJS from "exceljs";

export const capitalize = (label: string) =>
  label?.charAt(0).toUpperCase() + label.slice(1);

export const dateAndTimeStamp = (dateStr: string, timeZone = "UTC") => {
  const date = new Date(dateStr);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: timeZone,
  };

  const formattedDate = date.toLocaleString("en-US", options);

  const [newDate, time] = formattedDate.split(",");

  const [month, day, year] = newDate.split("/");

  return `${day}/${month}/${year}, ${time}`;
};

export const getFormattedDate = (date: string) => {
  if (date) {
    const newDate = new Date(date);
    const month =
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`;

    const day =
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();

    const year = newDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return "-";
};

export const getSignUpData = (data: any, addressDetails: any) => {
  const type = data.userType.formData.devType;
  const {
    userType,
    personalDetails,
    aadhaarVerification,
    documentUpload,
    organisationDetails,
  } = data;
  if (type === "ORGANIZATION") {
    return {
      userType: userType.formData.devType,
      role: userType.formData.userType,
      firstName: organisationDetails.formData.AuthorisedRepresentativeName, //  representativeName
      email: organisationDetails.formData.email,
      countryCode: organisationDetails.formData.phone.countryCode,
      phoneNumber: organisationDetails.formData.phone.number,
      password: organisationDetails.formData.password,
      isAddressSame: !!addressDetails.isAddressSame,
      // notificationPreference: 1,
      ...(!addressDetails.isAddressSame && {
        currentAddress: {
          //in cse of false
          addressLine1: addressDetails?.currentAddress.address,
          // addressLine2: "",
          // village: "Village_name",
          city: addressDetails?.currentAddress.city,
          district: addressDetails?.currentAddress.district,
          state: addressDetails?.currentAddress.state,
          country: "india",
          isoCode: "INR",
          zipCode: "90210",
          // latitude: "0.3",
          // longitude: "0.7",
        },
      }),

      organizationDetail: {
        organizationName: organisationDetails.formData.organisationName,
        organizationType: organisationDetails.formData.organisationType,
        representativeDesignation: "Manage",

        documentDetail: {
          type: documentUpload.formData.documentType,
          serialNo: documentUpload.formData.documentImage[0]?.key, // `${generateRandomString(10)}`,
          frontUrl: documentUpload.formData.documentImage[0]?.file,
          backUrl: documentUpload.formData.documentImage[0]?.file,
          isVerified: false,
          // validFrom: documentUpload.formData.registration || undefined,
        }, //  same as aadhar structure, add one more key "validFrom" for date of registration
        // notificationPreference: 1,
      },
      permanentAddress: {
        //permanent
        addressLine1: addressDetails?.permanentAddress.address,
        // addressLine2: "ddsadas",
        village: "dsada",
        city: addressDetails?.permanentAddress.city,
        district: addressDetails?.permanentAddress.district,
        state: addressDetails?.permanentAddress.state,
        country: "india",
        isoCode: "INR",
        zipCode: "90210",
        // latitude: "0.3",
        // longitude: "0.7",
      },
    };
  }

  return {
    userType: userType.formData.devType,
    role: userType.formData.userType,
    firstName: personalDetails.formData.fullName,
    email: personalDetails.formData.email,
    countryCode: personalDetails.formData.phone.countryCode,
    phoneNumber: personalDetails.formData.phone.number,
    password: personalDetails.formData.password,
    isAddressSame: !!addressDetails.isAddressSame, // in case true then permananent otherwise both
    // notificationPreference: 1, // to be remove
    permanentAddress: {
      addressLine1: addressDetails?.permanentAddress.address,
      // addressLine2: "ddsadas",
      // village: "dsada",
      city: addressDetails?.permanentAddress.city,
      district: addressDetails?.permanentAddress.district,
      state: addressDetails?.permanentAddress.state,
      country: "india",
      isoCode: "INR",
      zipCode: addressDetails.permanentAddress.pinCode, // pin code
      // latitude: "0.3",
      // longitude: "0.7",
    },
    ...(!addressDetails.isAddressSame && {
      currentAddress: {
        addressLine1: addressDetails?.currentAddress.address,
        // addressLine2: "ddsadas",
        // village: "dsada",
        city: addressDetails?.currentAddress.city,
        district: addressDetails?.currentAddress.district,
        state: addressDetails?.currentAddress.state,
        country: "india",
        isoCode: "INR",
        zipCode: addressDetails.permanentAddress.pinCode,
      },
    }),
    aadhaarDetails: {
      type: "AADHAAR",
      serialNo: aadhaarVerification.formData.aadhaarCard[0]?.key,
      frontUrl: aadhaarVerification.formData.aadhaarCard[0]?.file,
      backUrl: aadhaarVerification.formData.aadhaarCardBack[0]?.file,
      isVerified: false,
    },
  };
};

export const optionTypeFomatter = (arr, key = false) => {
  if (!arr?.length) return [];
  return arr?.map(({ name, id, isActive }) => {
    return {
      label: name,
      value: key ? name : id,
      isActive: isActive,
      id: id,
    };
  });
};
export const optionTypeFomatterByType = (arr, key = false) => {
  if (!arr?.length) return [];
  return arr?.map(({ type, id, isActive }) => {
    return {
      label: type,
      value: key ? type : id,
      isActive: isActive,
      id: id,
    };
  });
};

export const createProjectData = (key: string, data: CreatePorjectForm) => {
  const {
    orgDetails,
    bankDetails,
    gcBankDetails,
    plotSiteAreaDetails,
    projectAreaDetails,
    // projectDetails,
  } = data;

  const { organizationAffiliationDetail } = orgDetails;

  if (key === devTypes.ORGANIZATION) {
    return {
      name: orgDetails.organizationAffiliationDetail.projectName,
      projectType: "TREE_PLANTATION",
      landArea: Number(plotSiteAreaDetails.landArea),
      areaUnit: plotSiteAreaDetails.areaUnit,
      landOwnershipTypeId: "e8b45136-850e-49d5-a601-8308736f3474",
      landCategoryId: plotSiteAreaDetails.landCategory,
      projectStartDate: plotSiteAreaDetails.startDate,
      projectDuration: Number(plotSiteAreaDetails.projectDuration),
      organizationAffiliationDetail: {
        noOfEmployee: Number(organizationAffiliationDetail.noOfEmployee),
        incomeBracketRangeId: "3bf75f28-88ef-4250-810a-19ad41ba54c2",
        annualTurnover: Number(organizationAffiliationDetail.annualTurnover),
        industryTypeId: organizationAffiliationDetail.industryTypeId,
      },
      treeSpecificationDetail: [
        {
          treeType: 0,
          treeCount: Number(plotSiteAreaDetails.existingTreeCount),
          speciesName: plotSiteAreaDetails.existingTree,
        },
        {
          treeSpeciesId: plotSiteAreaDetails.treeSpicies,
          treeType: 1,
          treeCount: Number(plotSiteAreaDetails.treeCount),
          ageOfSaplings: Number(plotSiteAreaDetails.ageOfSaplings),
          sourceOfSaplings: plotSiteAreaDetails.sourceOfSaplings,
        },
      ],

      bankAccountDetail: {
        bankName: bankDetails.bankName,
        ifscCode: bankDetails.ifscCode,
        accountHolderName: bankDetails.accountHolderName,
        accountNumber: bankDetails.accountNumber,
        branchState: bankDetails.branchState,
        branchDistrict:
          bankDetails.branchDistrict && bankDetails.branchDistrict.id,
        branchLocation: bankDetails.branch,
      },
      address: {
        addressLine1: "string",
        city: projectAreaDetails.city,
        district: projectAreaDetails.district && projectAreaDetails.district.id,
        state: projectAreaDetails.state,
        country: "india",
        isoCode: "string",
        zipCode: "string",
        latitude: 40.7128,
        longitude: 40.7128,
      },
      documentDetail: [
        ...organizationAffiliationDetail.projectProfileUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...projectAreaDetails.documentUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...plotSiteAreaDetails.sourceSaplingImg.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...plotSiteAreaDetails.ownershipImg.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...plotSiteAreaDetails.plantationImg.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...bankDetails.documentDetailUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...gcBankDetails.documentDetailUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
      ],
    };
  } else if (key === devTypes.INDIVIDUAL) {
    return {
      name: orgDetails.individualDetails.projectName,
      projectType: ProjectType.TREE_PLANTATION,
      landArea: Number(plotSiteAreaDetails.landArea),
      areaUnit: plotSiteAreaDetails.areaUnit,
      landOwnershipTypeId: plotSiteAreaDetails.ownerShip,
      landCategoryId: plotSiteAreaDetails.landCategory,
      projectStartDate: plotSiteAreaDetails.startDate,
      projectDuration: Number(plotSiteAreaDetails.projectDuration),
      organizationAffiliationDetail: {
        incomeBracketRangeId: orgDetails.individualDetails.annualIncome,
        // annualTurnover: Number(orgDetails.individualDetails.annualIncome),
      },

      treeSpecificationDetail: [
        {
          treeType: 0,
          treeCount: Number(plotSiteAreaDetails.existingTreeCount),
          speciesName: plotSiteAreaDetails.existingTree,
        },
        {
          treeSpeciesId: plotSiteAreaDetails.treeSpicies,
          treeType: 1,
          treeCount: Number(plotSiteAreaDetails.treeCount),
          ageOfSaplings: Number(plotSiteAreaDetails.ageOfSaplings),
          sourceOfSaplings: plotSiteAreaDetails.sourceOfSaplings,
        },
      ],

      bankAccountDetail: {
        bankName: bankDetails.bankName,
        ifscCode: bankDetails.ifscCode,
        accountHolderName: bankDetails.accountHolderName,
        accountNumber: bankDetails.accountNumber,
        branchState: bankDetails.branchState,
        branchDistrict:
          bankDetails.branchDistrict && bankDetails.branchDistrict?.id,
        branchLocation: bankDetails.branch,
        documentDetailUrl: bankDetails.documentDetailUrl,
      },
      address: {
        addressLine1: "string",
        city: projectAreaDetails.city,
        district:
          projectAreaDetails.district && projectAreaDetails.district?.id,
        state: projectAreaDetails.state,
        country: "india",
        isoCode: "string",
        zipCode: "string",
        latitude: 40.7128,
        longitude: 40.7128,
      },
      documentDetail: [
        ...orgDetails.individualDetails.projectProfileUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...projectAreaDetails.documentUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...plotSiteAreaDetails.sourceSaplingImg.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...plotSiteAreaDetails.ownershipImg.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...plotSiteAreaDetails.plantationImg.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...bankDetails.documentDetailUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
        ...gcBankDetails.documentDetailUrl.map((item: any) => {
          return { documentUrl: item.key, type: item.type };
        }),
      ],
    };
  }
  return {};
};

export const createProjectDataForWater = (
  key: string,
  data: CreatePorjectForm
) => {
  const {
    waterPlotArea,
    waterProjectArea,
    waterStructureDetails,
    waterEntityDetails,
    bankDetails,
  } = data;
  if (key === devTypes.ORGANIZATION) {
    return {
      name: waterEntityDetails.projectName,
      // "projectTypeId": "b96b19de-ff65-4727-86c5-6880dd550f9f", // Water
      projectType: ProjectType.WATER_CONSERVATION,
      landArea: Number(waterPlotArea.area),
      areaUnit: "ha",
      landOwnershipTypeId: waterPlotArea.ownership,
      landCategoryId: waterPlotArea.landUse,
      // projectStartDate: localDataConverter(waterStructureDetails.startDate),
      projectStartDate: "2014-04-17T13:11:30.000+01:00",
      projectDuration: Number(waterStructureDetails.projectDuration),
      organizationAffiliationDetail: {
        noOfEmployee: Number(waterEntityDetails.noOfEmployee),
        incomeBracketRangeId: "3bf75f28-88ef-4250-810a-19ad41ba54c2",
        annualTurnover: Number(waterEntityDetails.annualTurnover),
        industryTypeId: waterEntityDetails.industryTypeId,
        projectProfileUrl: "organizationAffiliationDetail.projectProfileUrl",
      },
      waterConservationDetail: {
        // water type details
        structureTypeId: waterStructureDetails.type,
        materialDetailId: waterStructureDetails.material,
        potentialCapacity: Number(waterStructureDetails.capacity),
        cost: Number(waterStructureDetails.cost),
      },
      treeSpecificationDetail: [],
      bankAccountDetail: {
        bankName: bankDetails.bankName,
        ifscCode: bankDetails.ifscCode,
        accountHolderName: bankDetails.accountHolderName,
        accountNumber: bankDetails.accountNumber,
        branchState: bankDetails.branchState,
        branchDistrict: bankDetails.branch,
        // bankDetails.branchDistrict && bankDetails.branchDistrict.id,
        branchLocation: bankDetails.branch,
        documentDetailUrl: bankDetails.documentDetailUrl,
      },
      address: {
        addressLine1: "string",
        city: waterProjectArea.city,
        district: waterProjectArea.district && waterProjectArea.district.id,
        state: waterProjectArea.state,
        country: "india",
        isoCode: "string",
        zipCode: "string",
        latitude: 40.7128,
        longitude: 40.7128,
      },
      documentDetail: [
        {
          documentUrl: "url id",
          type: "LAND_OWNERSHIP",
        },
      ],
    };
  } else {
    return {
      name: waterEntityDetails.projectName,
      projectType: ProjectType.WATER_CONSERVATION,
      landArea: Number(waterPlotArea.area),
      areaUnit: "ha",
      landOwnershipTypeId: waterPlotArea.ownership,
      landCategoryId: waterPlotArea.landUse,
      projectStartDate: "2014-04-17T13:11:30.000+01:00",
      projectDuration: Number(waterStructureDetails.projectDuration),
      organizationAffiliationDetail: {
        incomeBracketRangeId: "3bf75f28-88ef-4250-810a-19ad41ba54c2",
      },
      waterConservationDetail: {
        // water type details
        structureTypeId: waterStructureDetails.type,
        materialDetailId: waterStructureDetails.material,
        potentialCapacity: Number(waterStructureDetails.capacity),
        cost: Number(waterStructureDetails.cost),
      },
      treeSpecificationDetail: [],
      bankAccountDetail: {
        bankName: bankDetails.bankName,
        ifscCode: bankDetails.ifscCode,
        accountHolderName: bankDetails.accountHolderName,
        accountNumber: bankDetails.accountNumber,
        branchState: bankDetails.branchState,
        branchDistrict: bankDetails.branch,
        // bankDetails.branchDistrict && bankDetails.branchDistrict.id,
        branchLocation: bankDetails.branch,
        documentDetailUrl: bankDetails.documentDetailUrl,
      },
      address: {
        addressLine1: "string",
        city: waterProjectArea.city,
        district: waterProjectArea.district && waterProjectArea.district.id,
        state: waterProjectArea.state,
        country: "india",
        isoCode: "string",
        zipCode: "string",
        latitude: 40.7128,
        longitude: 40.7128,
      },
      documentDetail: [
        {
          documentUrl: "url id",
          type: "LAND_OWNERSHIP",
        },
      ],
    };
  }
};

export const manipulatePlantationFormData = (data: any) => {
  return {
    project: data.projectType,
    orgDetails: {
      organizationAffiliationDetail: {
        name: data.organizationAffiliationDetail.name,
        organisationType: data.organizationAffiliationDetail.id,
        noOfEmployee: data.organizationAffiliationDetail.noOfEmployee,
        annualTurnover: data.organizationAffiliationDetail.annualTurnover,
        industryTypeId: data.organizationAffiliationDetail.industryTypeId,
        incomeBracketRangeId:
          data.organizationAffiliationDetail.incomeBracketRangeId,
        projectProfileUrl: [],
        projectName: data.name,
      },
    },

    plotSiteAreaDetails: {
      img: [],
      landArea: data.landArea,
      areaUnit: data.areaUnit,
      landCategory: data.landCategoryId,
      ownerShip: data.landOwnershipTypeId,
      spiciesType: data.treeSpecificationDetail.length
        ? data.treeSpecificationDetail[0]?.speciesName
        : "",
      treeSpicies: data.treeSpecificationDetail.length
        ? data.treeSpecificationDetail[0]?.treeSpeciesId
        : "",
      treeCount: data.treeSpecificationDetail.length
        ? data.treeSpecificationDetail[0]?.treeCount
        : "",
      layoutPlantation: "",
      ageOfSaplings: data.treeSpecificationDetail.length
        ? data.treeSpecificationDetail[0]?.ageOfSaplings
        : "",
      sourceOfSaplings: data.treeSpecificationDetail.length
        ? data.treeSpecificationDetail[0]?.sourceOfSaplings
        : "",
      startDate: data.projectStartDate,
      projectDuration: data.projectDuration,
    },

    bankDetails: {
      bankName: data.bankAccountDetail.bankName,
      ifscCode: data.bankAccountDetail.ifscCode,
      accountHolderName: data.bankAccountDetail.accountHolderName,
      accountNumber: data.bankAccountDetail.accountNumber,
      branchState: data.bankAccountDetail.branchState,
      branchDistrict: data.bankAccountDetail.branchDistrict,
      branch: data.bankAccountDetail.branchLocation,
      documentDetailUrl: [],
    },
    treeSpecificationDetail: [
      {
        id: "9a4e8e2b-bb38-4ed9-8179-1e73a0561f16",
        treeSpeciesId: "e233c9ea-1693-49d1-b9c0-9c3571c7150c",
        // "speciesName": "asd",
        treeType: 0,
        treeCount: 3,
        ageOfSaplings: 333,
        sourceOfSaplings: "Nursery A4",
      },
      {
        id: "82eb4f79-0763-4c48-9b43-6421c7e2f8f7",
        treeSpeciesId: "e233c9ea-1693-49d1-b9c0-9c3571c7150c",
        // "speciesName": "asd",
        treeType: 1,
        treeCount: 5,
        ageOfSaplings: 555,
        sourceOfSaplings: "Nursery B4",
      },
    ],
    projectAreaDetails: {
      img: [],
      addressLine1: data.address.addressLine1,
      addressLine2: null,
      village: data.address.village,
      city: data.address.city,
      district: data.address.district,
      state: data.address.state,
      country: data.address.country,
      isoCode: data.address.isoCode,
      zipCode: data.address.zipCode,
      latitude: data.address.latitude,
      longitude: data.address.longitude,
    },

    gcBankDetails: {
      documentDetailUrl: [],
      bankName: "",
      ifscCode: "",
      accountHolderName: "",
      accountNumber: null,
      branch: "",
      state: "",
    },

    documentDetail: [
      {
        id: "72080c39-273f-40f3-8608-da6094411e43",
        serialNo: null,
        documentUrl:
          "https://greencreditbucket.s3.ap-south-1.amazonaws.com/greenCreditKeyPrefix1699266154133?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVCOYGCWF3XK2CG6I%2F20231106%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231106T111314Z&X-Amz-Expires=120&X-Amz-Signature=41ac92a6e049ad59a40437098c98ccfe758fa2e6c6dda633b21a927d34320104&X-Amz-SignedHeaders=host",
        isVerified: false,
        type: "LAND_OWNERSHIP",
        validFrom: null,
        validTill: null,
      },
    ],
    images: [],

    provisionalDetails: [
      {
        year: 2.5,
        percentage: 25,
      },
      {
        year: 5,
        percentage: 25,
      },
      {
        year: 7.5,
        percentage: 25,
      },
      {
        year: 10,
        percentage: 25,
      },
    ],
  };
};

export const localDateConverter = (dateString: string) => {
  const date = new Date(dateString);
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear()
  );
};

// export function downloadSheet(data: any) {
//   const worksheet = XLSX.utils.json_to_sheet(data);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

//   const excelBuffer = XLSX.write(workbook, {
//     bookType: "xlsx",
//     type: "array",
//   });
//   const blob = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//   });

//   saveAs(blob, "data.xlsx");
// }

export function downloadSheet(data: any) {
  // Create a worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Adjust column width
  const columnWidths = data.length
    ? Object.keys(data[0]).map((key) => ({
        wch: Math.max(
          key.length, // header width
          ...data.map((row: any) => (row[key] ? row[key].toString().length : 0)) // cell width
        ),
      }))
    : [];

  worksheet["!cols"] = columnWidths;

  // Optional: Adjust row height for the header (popular height)
  const popularHeaderHeight = 30; // Example height in points
  worksheet["!rows"] = [{ hpt: popularHeaderHeight }]; // Header height

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate an Excel file in buffer format
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Create a Blob from the buffer and trigger download
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  saveAs(blob, "data.xlsx");
}

interface Entry {
  numberOfEntries: number;
  totalArea: number;
}

interface StateData {
  [stateName: string | number]: {
    [status: string | number]: Entry;
  }[];
}

export function formatData(stats: StateData[]) {
  const formattedData: string[][] = [];

  stats.forEach((stateStats, index) => {
    const stateName = Object.keys(stateStats)[0]; // Extract state name
    const entries = stateStats[stateName]; // Extract entries for the state
    let stateEntries: string[] = [];
    stateEntries.push((index + 1).toString(), stateName);
    entries.forEach((entry) => {
      if (entry) {
        const statusName = Object.keys(entry)[0]; // Extract status name
        // const { numberOfEntries, totalArea } = entry[statusName]; // Extract number of entries and total area
        stateEntries.push(
          entry[statusName][Object.keys(entry[statusName])[0]],
          entry[statusName][Object.keys(entry[statusName])[1]]
        );
      } else {
        stateEntries.push("0", "0");
      }
    });
    formattedData.push(stateEntries);
  });

  console.log(formattedData);
  return formattedData;
}

export function extractStates(data) {
  const firstEntity = data?.stats[0];
  const entityName = Object.keys(firstEntity)[0]; // Get the name of the first entity
  const statesArray = firstEntity[entityName].map(
    (stateObj) => Object.keys(stateObj)[0]
  ); // Extract states

  return statesArray;
}

export const formatDataForBarChart = (stats) => {
  return stats?.map((stat) => ({
    name: stat.description,
    uv: stat.number,
    pv: Number(stat.totalArea),
    amt: stat.totalArea,
  }));
};

export const formatToIndianCurrency = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber.replace("₹", "₹ ");
};

export const formatINR = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber.replace("₹", "");
};

export const TableformatToIndianCurrency = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    currencyDisplay: "code",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber.replace("INR", "").trim();
};

export async function exportTableToXlsx(data, header, topHeader) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  // Define styles
  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  // Add top header row and apply styles and merges
  let topHeaderColStart = 1;
  topHeader.forEach((headerTitle, index) => {
    worksheet.getCell(1, topHeaderColStart).value = headerTitle;
    worksheet.getCell(1, topHeaderColStart).style = greyHeaderStyle;
    worksheet.mergeCells(1, topHeaderColStart, 1, topHeaderColStart + 1); // Merging cells for the top header
    topHeaderColStart += 2; // Move to the next pair of columns
  });

  // Add and style header row
  const headerRowIndex = 2; // The header row will be the second row
  worksheet.addRow(header); // Add header values
  header.forEach((item, index) => {
    worksheet.getCell(headerRowIndex, index + 1).style = greyHeaderStyle;
  });

  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.state,
        row.totalNumberRegistered,
        row.totalAreaRegistered,
        row.totalNumberGenerateDemandNote,
        row.totalAreaGenerateDemandNote,
        row.totalNumberNodalUnderQuery,
        row.totalAreaNodalUnderQuery,
        row.totalNumberVerifyDemandNote,
        row.totalAreaVerifyDemandNote,
        row.totalNumberUnderQuery,
        row.totalAreaUnderQuery,
        row.totalNumberPaymentPending,
        row.totalAreaPaymentPending,
        row.totalNumberPaymentDone,
        row.totalAreaPaymentDone,
        row.totalNumberDemandNoteGenerated,
        row.totalAreaDemandNoteGenerated,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });

  // Generate the file and save it
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}

export async function exportEcoRestorationTableToXlsx(data, header, topHeader) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  // Define styles
  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  // Add top header row and apply styles and merges
  let topHeaderColStart = 1;
  topHeader.forEach((headerTitle, index) => {
    worksheet.getCell(1, topHeaderColStart).value = headerTitle;
    worksheet.getCell(1, topHeaderColStart).style = greyHeaderStyle;
    worksheet.mergeCells(1, topHeaderColStart, 1, topHeaderColStart + 1); // Merging cells for the top header
    topHeaderColStart += 2; // Move to the next pair of columns
  });

  // Add and style header row
  const headerRowIndex = 2; // The header row will be the second row
  worksheet.addRow(header); // Add header values
  header.forEach((item, index) => {
    worksheet.getCell(headerRowIndex, index + 1).style = greyHeaderStyle;
  });

  // Add data rows with center alignment
  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.state,
        row.totalNumberRegistered,
        row.totalAreaRegistered,
        row.totalNumberNodalVerificationPending,
        row.totalAreaNodalVerificationPending,
        row.totalNumberVerificationPending,
        row.totalAreaVerificationPending,
        row.totalNumberNodalUnderQuery,
        row.totalAreaNodalUnderQuery,
        row.totalNumberUnderQuery,
        row.totalAreaUnderQuery,
        row.totalNumberApproved,
        row.totalAreaApproved,
        row.totalNumberRejected,
        row.totalAreaRejected,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });

  // Generate the file and save it
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}

export async function exportEntityTableToXlsx(data, header, topHeader) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  let topHeaderColStart = 4;
  topHeader.forEach((headerTitle, index) => {
    if (headerTitle == "") return;
    worksheet.getCell(1, topHeaderColStart).value = headerTitle;
    worksheet.getCell(1, topHeaderColStart).style = greyHeaderStyle;
    console.log(headerTitle, 1, topHeaderColStart, 1, topHeaderColStart + 1);
    worksheet.mergeCells(1, topHeaderColStart, 1, topHeaderColStart + 1); // Merging cells for the top header
    topHeaderColStart += 2; // Move to the next pair of columns
  });

  // Merge the first three columns in the top header row
  worksheet.mergeCells(1, 1, 1, 3);
  worksheet.getCell(1, 1).value = ""; // You can set this value if needed
  worksheet.getCell(1, 1).style = greyHeaderStyle;

  // Add and style header row
  const headerRowIndex = 2; // The header row will be the second row
  worksheet.addRow(header); // Add header values
  header.forEach((item, index) => {
    worksheet.getCell(headerRowIndex, index + 1).style = greyHeaderStyle;
  });

  // Add data rows with center alignment
  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.entityName,
        row.state,
        row.totalBlocks,
        row.totalBlocksArea,
        row.totalNumberOfGenerateDemandNote,
        row.totalAreaOfGenerateDemandNote,
        row.totalNumberOfVerificationPending,
        row.totalAreaOfVerificationPending,
        row.totalNumberOfDemandNotePending,
        row.totalAreaOfDemandNotePending,
        row.totalNumberOfDemandNoteReleased,
        row.totalAreaOfDemandNoteReleased,
        row.numberPaymentPending,
        row.areaPaymentPending,
        row.numberPaymentDone,
        row.areaPaymentDone,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });

  // Generate the file and save it
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}

export async function exportSelectedLandTable(data, header, topHeader) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  // Define styles
  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  // Add top header row and apply styles and merges
  let topHeaderColStart = 1;
  topHeader.forEach((headerTitle, index) => {
    worksheet.getCell(1, topHeaderColStart).value = headerTitle;
    worksheet.getCell(1, topHeaderColStart).style = greyHeaderStyle;
    worksheet.mergeCells(1, topHeaderColStart, 1, topHeaderColStart + 1); // Merging cells for the top header
    topHeaderColStart += 2; // Move to the next pair of columns
  });

  // Add and style header row
  const headerRowIndex = 2; // The header row will be the second row
  worksheet.addRow(header); // Add header values
  header.forEach((item, index) => {
    worksheet.getCell(headerRowIndex, index + 1).style = greyHeaderStyle;
  });

  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.state,
        row.totalNumberRegistered,
        row.totalAreaRegistered,
        row.totalNumberApproved,
        row.totalAreaApproved,
        row.totalNumberSelected,
        row.totalAreaSelected,
        row.totalNumberAvailable,
        row.totalAreaAvailable,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });

  // Generate the file and save it
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}

export function mergerHeader(customTopHeader, worksheet: any) {
  const greyHeaderStyle = {
    fill: {
      type: "pattern" as const,
      pattern: "solid" as const,
      fgColor: { argb: "FFDDDDDD" as const },
    },
    font: { bold: true },
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  let start = 1;
  customTopHeader.forEach((topHeader, i) => {
    const currLeft = start;
    const currRight = currLeft + topHeader.size - 1;
    worksheet.getCell(1, currLeft).value = topHeader.title;
    worksheet.getCell(1, currLeft).style = greyHeaderStyle;
    worksheet.mergeCells(1, currLeft, 1, currRight);
    start = currRight + 1;
  });
}

export async function fundApplicationExportTableToXlsx(
  data,
  header,
  customTopHeader
) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("DashboardTable");

  // Define styles
  const centerStyle = {
    alignment: { horizontal: "center" as const, vertical: "middle" as const },
    border: {
      top: { style: "thin" as const },
      left: { style: "thin" as const },
      bottom: { style: "thin" as const },
      right: { style: "thin" as const },
    },
  };

  // Add top header row and apply styles and merges

  mergerHeader(customTopHeader, worksheet);

  worksheet.addRow(header); // Add header values

  // Add data rows
  data.forEach((row) => {
    worksheet
      .addRow([
        row.sno,
        row.state,
        row.totalNumberApproved,
        row.totalAreaApproved,
        row.totalNumberSelected,
        row.totalAreaSelected,
        row.totalNumberDemandNoteRaised,
        row.totalAreaDemandNoteRaised,
        row.totalAmountDemandNoteRaised,
        row.totalNumberPaymentReceived,
        row.totalAreaPaymentReceived,
        row.totalAmountPaymentReceived,
        row.totalNumberFundApplicationPending,
        row.totalAreaFundApplicationPending,
        row.totalNumberFundApplicationReceived,
        row.totalAreaFundApplicationReceived,
        row.totalAmountFundApplicationReceived,
        row.totalNumberFirstYearPaymentReleased,
        row.totalAreaFirstYearPaymentReleased,
        row.totalAmountFirstYearPaymentReleased,
        row.totalAmountFundApplicationPending,
        row.totalBalanceWithAdmin,
      ])
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.style = centerStyle;
      });
  });
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "DashboardTable.xlsx"
  );
}
