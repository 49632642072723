import Close from "@mui/icons-material/Close";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
} from "@mui/material";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getError } from "src/utils/common";
import defaultStyles, { StylesClasses } from "./styles";

type InputProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label?: string;
    errors?: FieldErrors;
    maxLength?: number;
    labelPos?: boolean;
    labelPosStyles?: boolean;
    readOnly?: boolean;
    showAdornment?: boolean;
    adornmentPos?: "start" | "end";
    iconSrc?: string;
    placeholder?: string;
    autoFocus?: boolean;
    isValid?: boolean;
    isAlpha?: boolean;
    height?: string;
    customStyles?: CustomStyles<StylesClasses>;
    multiline?: boolean;
  };

export const Input = <T extends FieldValues>({
  name,
  control,
  label,
  type = "text",
  fullWidth = true,
  labelPos = false,
  labelPosStyles = false,
  errors,
  disabled = false,
  rules,
  maxLength,
  inputProps,
  placeholder,
  isAlpha = false,
  showAdornment = false,
  adornmentPos = "end",
  variant = "standard",
  className,
  isValid = false,
  readOnly = false,
  autoFocus = false,
  height = "40px",
  multiline = false,
  customStyles,
  ...rest
}: InputProps<T>) => {
  const error = getError(name, errors);
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const handleKeyDown = (event) => {
    if (type === "number" && (event.key === "e" || event.key === "E")) {
      event.preventDefault();
    }
  };
  return (
    <Controller
      render={({ field }) => (
        <Box
          {...styles("wrapper", {
            color: readOnly ? "custom.text.secondary" : "initial",
          })}
        >
          {label && (
            <InputLabel
              shrink
              {...styles("label", {
                color: readOnly ? "custom.text.secondary" : "initial",
              })}
              required={!!rules?.required}
              htmlFor={name}
            >
              {label}
            </InputLabel>
          )}
          <InputBase
            id={name}
            {...styles("input", {
              color: readOnly ? "custom.text.secondary" : "initial",
              height: { height },
            })}
            type={type}
            multiline={multiline}
            rows={5}
            value={field?.value}
            disabled={disabled}
            onChange={field.onChange}
            inputRef={field.ref}
            onBlur={field.onBlur}
            readOnly={readOnly}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            autoFocus={autoFocus}
            inputProps={{ maxLength: maxLength, ...inputProps }}
            endAdornment={
              <InputAdornment
                aria-label="Search Icon"
                {...styles("adornment", {
                  display:
                    showAdornment && adornmentPos === "start"
                      ? "block"
                      : "none",
                })}
                position="start"
              >
                <SearchIcon />
              </InputAdornment>
            }
            startAdornment={
              <InputAdornment
                {...styles("adornment", {
                  display:
                    showAdornment && adornmentPos === "end" ? "block" : "none",
                })}
                position="end"
              >
                {!!error ? (
                  <Close color="error" />
                ) : (
                  field.value !== "" && (
                    <DoneRoundedIcon {...styles("doneIcon")} />
                  )
                )}
              </InputAdornment>
            }
            error={!!error}
            {...rest}
          />
          {error && (
            <FormHelperText {...styles("errorMsg")}>
              {error?.message}
            </FormHelperText>
          )}
        </Box>
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};
