import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBaseProps,
  InputLabel,
} from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  UseFormClearErrors,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { useMutation } from "react-query";
import errorMessage from "src/constants/errorMessage";
import { CustomStyles, getStyles } from "src/styles/theme";
import { fileUpload, fileUploadOcr } from "src/utils/api/fileupload";
import { getError } from "src/utils/common";
import { Button } from "../Buttons";
import defaultStyles, { StylesClasses } from "./styles";

type FileUploadProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    errors?: FieldErrors;
    customStyles?: CustomStyles<StylesClasses>;
    label?: string;
    multiple?: boolean;
    maxLimit?: number;
    version?: "standard" | "lite";
    setValue: UseFormSetValue<T>;
    getValues: UseFormGetValues<T>;
    setError: UseFormSetError<T>;
    clearErrors: UseFormClearErrors<T>;
    defaultFiles?: { key: string; file: string }[];
    accept?: string;
    maxFileSize?: number;
    loading?: boolean;
    dragDropHeading?: string;
    supportedFormatMessage?: string;
    trigger?: UseFormTrigger<T>;
    getFieldState?: UseFormGetFieldState<T>;
    hideUpload?: boolean;
    dragDropText?: string;
    onSuccess?: (res: AxiosResponse) => void;
    reqOcr?: boolean;
    required?: boolean;
    imageType?: string;
    disabled?: boolean;
    uploadMetaData?:
      | {
          documentType: string;
          name?: string;
          type?: string;
          raw: boolean;
          dob?: string;
          docId?: string;
          nationality?: string;
        }
      | {};
    // formState?: UseFormStateProps<T>; // isDirty?: UseForm
  };

const FileUpload = <T extends FieldValues>({
  name,
  setValue,
  getValues,
  setError,
  clearErrors,
  label,
  control,
  fullWidth = true,
  errors,
  maxLimit = 5,
  multiple = false,
  version = "standard",
  rules,
  className,
  maxFileSize = 10485760,
  customStyles,
  accept = "image/jpeg, image/png, image/webp, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,  .doc, .docx, .xml",
  supportedFormatMessage = "Supported formats: images, pdf and Docs Size Upto 10MB",
  loading,
  disabled = false,
  defaultFiles,
  trigger,
  required = true,
  hideUpload = false,
  dragDropText = "Drag Drop your file here",
  getFieldState,
  uploadMetaData = {},
  dragDropHeading = "",
  reqOcr = false,
  imageType = "default",
  onSuccess,
  ...rest
}: FileUploadProps<T>) => {
  const ref = useRef(null);
  const [files, setFile] = useState<any>(getValues(name) || []);
  const error = getError(name, errors);
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  const { isLoading: isLoadingUpload, mutate: uploadFile } = useMutation(
    "fileUpload",
    (data: any) => fileUpload(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setFile([
          ...files,
          {
            type: imageType,
            file: res.data.data.uploadedFile.fileUrl,
            key: res?.data?.data?.uploadedFile.key,
          },
        ]);
        !!onSuccess && onSuccess(res.data);
      },
      onError: (err: AxiosError) => {
        setError(name, {
          type: "invalidDocument",
          message: "Invalid Document",
        });
      },
    }
  );

  const { isLoading, mutate: uploadFileOcr } = useMutation(
    "fileUploadOcr",
    (data: any) => fileUploadOcr(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setFile([
          ...files,
          {
            type: imageType,
            file: res.data.data.fileUrl,
            key: res?.data?.data?.key,
          },
        ]);
        !!onSuccess && onSuccess(res.data);
      },
      onError: (err: AxiosError) => {
        const errRes = err.response.data;
        setError(name, {
          type: "invalidDocument",
          message: errRes.message,
        });
      },
    }
  );

  useEffect(() => {
    if (defaultFiles?.length && !files.length) {
      setFile(defaultFiles.slice(0, maxLimit));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFiles]);

  useEffect(() => {
    const file = files?.length ? files : [];
    setValue(name, [...file] as PathValue<T, Path<T> & string>);
    trigger(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const fileType = accept.split(",").map((str) => str.trim());

  const handleError = (file: File) => {
    if (file.size > maxFileSize) {
      setError(name, {
        type: "sizeLimit",
        message: errorMessage.fileUpload.maxFileSize(
          maxFileSize / (1024 * 1024)
        ),
      });
      return true;
    }

    if (!fileType?.includes(file?.type)) {
      setError(name, {
        type: "invalidFiletype",
        message: errorMessage.fileUpload.invalidFileType,
      });
      return true;
    }
    clearErrors(name);
    return false;
  };

  const fileInputRef = useRef(null);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors(name);
    let allFiles = { ...e.target.files };
    let length = e.target.files.length;

    if (maxLimit - files.length < length) {
      setError(name, {
        type: "maxLimit",
        message: errorMessage.fileUpload.fileLimitExceed(maxLimit),
      });
      return;
    }

    let rightFiles: File[] = [];
    for (let key in allFiles) {
      const err = handleError(allFiles[key]);
      if (!err) {
        let images = new FormData();

        if (reqOcr) {
          images.append("image", e.target.files?.[0]);

          Object.keys(uploadMetaData).map((item) =>
            images.append(item, uploadMetaData[item])
          );
        } else {
          images.append("file", e.target.files?.[0]);
        }
        reqOcr ? await uploadFileOcr(images) : await uploadFile(images);

        // await uploadFile(images);
        rightFiles.push(allFiles[key]);
      }
    }
  };

  const clearImage = (files, idx) => {
    /*  let filteredFiles = files?.filter((file) => {
      return file !== files[idx];
    }); */

    let filteredFiles = files.filter((file, index) => {
      return index !== idx;
    });

    if (fileInputRef?.current?.value) fileInputRef.current.value = "";
    if (ref?.current?.value) fileInputRef.current.value = "";

    setFile(filteredFiles);
  };

  return (
    <Box {...styles("container")}>
      <Box {...styles("outerLayer")} id="scrollableDiv">
        <Box>
          {files?.map((item, idx) => (
            <Box {...styles("fileUploadPreview")} key={idx}>
              <Box {...styles("overviewItemText")}>
                <Box
                  {...styles("receiptPreview")}
                  component={"img"}
                  src={
                    !!item.file
                      ? item.file
                      : "/assets/images/NoImageFoundIcon.png"
                  }
                  onError={(
                    e: React.SyntheticEvent<HTMLImageElement, Event>
                  ) => {
                    const target = e.target as HTMLImageElement;
                    target.src = "/assets//svg/receipt.svg";
                  }}
                  alt="file_image"
                />

                <Box {...styles("fileName")}>{`${item.key}-File-${idx + 1}
                `}</Box>
              </Box>
              <Box onClick={(e) => e.stopPropagation()} {...styles("tickIcon")}>
                <Box {...styles("imageBox")}>
                  <Box
                    component={"img"}
                    src="/assets/images/fileUploadSuccessIcon.png"
                    alt="tick_icon"
                  />

                  <Box {...styles("uploaded")}>Uploaded</Box>
                </Box>

                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    clearImage(files, idx);
                  }}
                  {...styles("crossIcon")}
                  component={"img"}
                  src="/assets/images/fileUploadCrossIcon.png"
                  alt="remove_icon"
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box>
          {files?.length < maxLimit && !hideUpload && (
            <Controller
              render={({ fieldState }) => (
                <>
                  {label && (
                    <InputLabel
                      shrink
                      {...styles(["label"])}
                      required={!!rules?.required || required}
                      htmlFor={name}
                      disabled={disabled}
                    >
                      {label}
                    </InputLabel>
                  )}
                  <FormControl
                    {...styles("wrapper", {
                      borderColor: "custom.border.otpDash",
                      /*    fieldState.invalid || getError(name, errors)
                          ? "error.main"
                          : "custom.border.otpDash", */
                    })}
                    fullWidth={fullWidth}
                    className={className}
                    required={!!rules?.required}
                  >
                    <Box
                      disabled={disabled}
                      // multiple={multiple}
                      component="input"
                      type="file"
                      accept={accept}
                      value=""
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                      {...styles("inputWrapper")}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        // e.preventDefault();
                      }}
                    />
                    <Box className="inputButton" />

                    <Box
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                      {...styles(
                        version === "standard" ? "contentStandard" : "content"
                      )}
                    >
                      <Box {...styles("dragAndDropArea")}>
                        <Box {...styles("dragArea")}>
                          <Box
                            {...(styles("dragText"),
                            {
                              color:
                                disabled === true
                                  ? "custom.disabled.btn"
                                  : "custom.text.dark",
                            })}
                          >
                            {dragDropText}&nbsp;
                            <Box
                              component="span"
                              {...styles("dragDropHeading")}
                            >
                              {dragDropHeading}
                            </Box>
                          </Box>
                        </Box>
                        <Box {...styles("uploadFile")}>
                          {version === "standard" && (
                            <Box
                              {...(styles("fileType"),
                              {
                                color:
                                  disabled === true
                                    ? "custom.disabled.btn"
                                    : "custom.label",
                              })}
                            >
                              {supportedFormatMessage}
                            </Box>
                          )}
                        </Box>
                        <Button
                          loading={isLoading || isLoadingUpload}
                          disabled={disabled}
                          // disabled={isLoading}
                          variant="outlined"
                          text="Upload File"
                          customStyles={{
                            root: defaultStyles.btn,
                            circle: { color: "primary.main" },
                          }}
                        />
                      </Box>
                    </Box>
                    {!!error && (
                      <FormHelperText {...styles("errorMsg")}>
                        {error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
              name={name}
              control={control}
              rules={rules}
              {...rest}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FileUpload;
