import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
  onEdit?: (row: any) => void;
  onDelete?: (row: any) => void;
  status?: string;
};

export function CostEstimateSummary({
  data,
  topHeader,
  onEdit,
  onDelete,
  status,
}: DashBoardTable) {
  const styles = getStyles(defaultStyles);
  const yearArray = [
    "1st Year",
    "2nd Year",
    "3rd Year",
    "4th Year",
    "5th Year",
    "6th Year",
    "7th Year",
    "8th Year",
    "9th Year",
    "10th Year",
    "Other Necessary Provisions",
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((yeardata, index) => {
            if (!yeardata) return null;
            return (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {yearArray[index]}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  ₹{yeardata}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
