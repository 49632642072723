import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundImage: "url('/assets/images/logInBackground.jpeg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  logInWrapper: {
    width: {
      lg: "35%",
      sm: "60%",
      xs: "80%",
    },
    maxWidth: "500px",
    height: "auto",
    borderRadius: "24px",
    padding: "48px 34px 120px 34px",
    position: "relative",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1%",
  },

  title: {
    color: "custom.text.label",
    fontSize: "20px",
    fontWeight: 600,
  },
  heading: {
    color: "custom.text.dark",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "16px",
    textAlign: "center",
  },

  spanStyle: {
    fontWeight: 600,
  },

  description: {
    textAlign: "center",
    fontWeight: 400,
    color: "custom.text.dark",
    marginBottom: "16px",
    width: "78%",
    margin: "auto",
  },

  emailField: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    margin: "16px 0px 20px 0px",
  },

  loginBtn: {
    width: "206px",
    height: "44px",
    margin: "auto",
  },
});

export default styles;
