import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  headerContainer: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: { xs: "space-between", lg: "flex-start" },
    gap: "13%",
    background: "#36b1a0",
    minHeight: "175px",
    width: "100%",
    padding: "10px 10px 0px 10px",
    position: "fixed",
    top: "0px",
    zIndex: "1100",
  },
  sponserHalf: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "30%",
  },

  GCP: {
    color: "white",
    fontSize: "50px",
    fontWeight: 700,
    textTransform: "uppercase",
  },

  heading: {
    color: "custom.text.white.100",
    fontSize: "28px",
    alignSelf: "center",
    fontWeight: "700",
    textTransform: "uppercase",
  },
});

export default styles;
