const errorMessage = {
  required: "This is a required field",
  invalidEmail: "Please enter a valid email address",
  invalidContact: "Please enter a valid contact number",
  unregisteredEMail: "Please enter a registered email address",
  emailExist: "Email already exists",
  pwdMinLength: "Minimum 8 characters required",
  minLength: "Minimum 5 number should be required",
  maxLength: "Maximum 8 number should be required",
  phoneLength: "Please Enter a valid Phone No",

  aadhar: {
    minLength: "Minimum 10 number should be required",
    maxLength: "Maximum 12 number should be required",
  },

  aadharPattern: "Please enter a valid Aadhaar number",

  digitRequired: (num: number) =>
    `This needs to be total of ${num} digit number`,

  fileUpload: {
    requiredFiles: "Please attach the required files",
    maxFileSize: (limit: number) => `Max Limit ${limit} MB`,
    invalidFileType: "Invalid File type",
    alreadyExist: "File already exist",
    fileLimitExceed: (maxLimit: number) =>
      `Please attach only ${maxLimit} files`,
    uploadLimitation: "Please upload one file at a time",
  },
  unknownErr: "Some error occurred",
  inValidCredentials: "Invalid credentials. Please try again.",
  invalidPassword: "Passwords do not match. Please try again.",
  password:
    "8 Characters | 1 Uppercase | 1 Lowercase | 1 Digit | 1 Special Character",
  validPccId: "Enter a valid PCC ID",
  createProject: {
    selectProject: "Please select atleast one of the projects",
  },
  phoneNumberLength: "Please enter 10 digit phone number",
};

export default errorMessage;
