import { Document, Link, Page, Text, View } from "@react-pdf/renderer";
import { ProjectType, PROJECT_DOCUMENT_TYPE_MAP } from "src/constants/common";
import { dateAndTimeStamp, getFormattedDate } from "src/utils/helper";
import styles from "../../pdfStyles";

const TreePdf = ({ projectData, userData, documents }) => {
  const projectType = projectData?.projectType;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.mainHeading}>
          Activity Registration No: {projectData?.registrationNo}
        </Text>

        <View>
          <Text style={styles.subHeading}>
            {userData.userType === "ORGANIZATION"
              ? "Entity Details"
              : "Individual Details"}
          </Text>
        </View>
        <Text style={styles.divider}></Text>
        <View style={styles.content}>
          <Text style={styles.label}>Username:</Text>
          <Text style={styles.value}>{userData?.firstName}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>Email:</Text>
          <Text style={styles.value}>{userData?.email}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>Phone Number:</Text>
          <Text style={styles.value}>{`${
            !!userData?.countryCode ? userData?.countryCode : "+91"
          } ${userData?.phoneNumber}`}</Text>
        </View>

        <View style={styles.content}>
          <Text style={styles.subHeading}>Activity Area Location Details</Text>
        </View>
        <Text style={styles.divider}></Text>
        <View style={styles.content}>
          <Text style={styles.label}>Address of the Plot:</Text>
          <Text style={styles.value}>{projectData?.address?.addressLine1}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>State:</Text>
          <Text style={styles.value}>{projectData?.address?.stateName}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>District:</Text>
          <Text style={styles.value}>{projectData?.address?.districtName}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>Latitude:</Text>
          <Text style={styles.value}>{projectData?.address?.latitude}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>Longitude:</Text>
          <Text style={styles.value}>{projectData?.address?.longitude}</Text>
        </View>
        {!!projectData?.address?.khasraNo && (
          <View style={styles.content}>
            <Text style={styles.label}>Khasra Number:</Text>
            <Text style={styles.value}>{projectData?.address?.khasraNo}</Text>
          </View>
        )}
        <View style={styles.content}>
          <Text style={styles.label}>Geotag Images:</Text>
        </View>

        {projectData?.images?.map((item, idx) => {
          return (
            <View style={styles.imagesWrapper}>
              <View style={styles.content}>
                <Link src={item?.imageUrl?.fileUrl}>{`Geotag_Image_${
                  idx + 1
                }`}</Link>
              </View>

              <View style={styles.content}>
                <Text style={styles.label}>
                  Captured date/ time (MM/DD/YYYY):
                </Text>
                <Text style={styles.value}>
                  {dateAndTimeStamp(item?.updated_at, "IST")}
                </Text>
              </View>
            </View>
          );
        })}

        <View>
          <Text style={styles.subHeading}>Plot/ Site/ Area Type Details</Text>
        </View>
        <Text style={styles.divider}></Text>
        <View style={styles.content}>
          <Text style={styles.label}>Site Area:</Text>
          <Text
            style={styles.value}
          >{`${projectData?.landArea} ${projectData?.areaUnit}`}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>Land Use Category:</Text>
          <Text style={styles.value}>{projectData?.projectSize}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.label}>Ownership:</Text>
          <Text style={styles.value}>{projectData?.landOwnershipType}</Text>
        </View>
        {projectType === ProjectType.TREE_PLANTATION && (
          <>
            <View style={styles.content}>
              <Text style={styles.subheader}>Tree Specifications:</Text>
            </View>
            <View>
              {projectData?.treeSpecificationDetail?.map((data) => {
                return (
                  <>
                    {data?.treeType === 0 ? (
                      <View>
                        <View style={styles.content}>
                          <Text style={styles.label}>
                            Existing Tree Species:
                          </Text>
                          <Text style={styles.value}>{data?.speciesName}</Text>
                        </View>
                        {!!data?.treeSpecies &&
                          Object?.keys(data?.treeSpecies)?.map((tree) => (
                            <View style={styles.content}>
                              <Text style={styles.label}>Name</Text>
                              <Text style={styles.value}>
                                {data?.treeSpecies?.tree}
                              </Text>
                            </View>
                          ))}
                        <View style={styles.content}>
                          <Text style={styles.label}>Existing Tree count:</Text>
                          <Text style={styles.value}>{data?.treeCount}</Text>
                        </View>
                      </View>
                    ) : (
                      <View>
                        <View style={styles.content}>
                          <Text style={styles.label}>
                            Tree Species Proposed:
                          </Text>
                          <Text style={styles.value}>{data?.speciesName}</Text>
                        </View>
                        {!!data?.treeSpecies && (
                          <>
                            <View style={styles.content}>
                              <Text style={styles.label}>Name:</Text>
                              <Text style={styles.value}>
                                {data?.treeSpecies?.name}
                              </Text>
                            </View>
                            <View style={styles.content}>
                              <Text style={styles.label}>Species Name:</Text>
                              <Text style={styles.value}>
                                {data?.treeSpecies?.speciesName}
                              </Text>
                            </View>
                            <View style={styles.content}>
                              <Text style={styles.label}>Species Type:</Text>
                              <Text style={styles.value}>
                                {data?.treeSpecies?.speciesType}
                              </Text>
                            </View>
                          </>
                        )}
                        <View style={styles.content}>
                          <Text style={styles.label}>
                            Number Of Trees Proposed:
                          </Text>
                          <Text style={styles.value}>{data?.treeCount}</Text>
                        </View>
                      </View>
                    )}
                  </>
                );
              })}
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>
                Start Date of Plantation (DD/MM/YYYY):
              </Text>
              <Text style={styles.value}>
                {getFormattedDate(projectData?.projectStartDate)}
              </Text>
            </View>

            <View style={styles.content}>
              <Text style={styles.label}>Activity Duration (in years):</Text>
              <Text style={styles.value}>{projectData?.projectDuration}</Text>
            </View>
          </>
        )}

        <View>
          <Text style={styles.subHeading}>Documents:</Text>
        </View>
        <View>
          <Text style={styles.divider}></Text>
        </View>
        {Object?.keys(documents)?.map((doc) => (
          <View style={styles.docWrapper}>
            <Text style={styles.multiValLabel}>
              {PROJECT_DOCUMENT_TYPE_MAP[doc]}
            </Text>
            <View style={styles.multiValue}>
              {documents[doc]?.map((item) => (
                <Link
                  src={item?.documentUrl?.fileUrl}
                >{`${PROJECT_DOCUMENT_TYPE_MAP[doc]}_1`}</Link>
              ))}
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default TreePdf;
