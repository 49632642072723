import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
};

export function CostEstimateDetailsTable({ data, topHeader }: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  const calculateRowSpan = (data, key) => {
    const rowSpans = {};
    let count = 0;
    let currentKey = null;

    data.forEach((row, index) => {
      if (row[key] !== currentKey) {
        currentKey = row[key];
        count = 1;
        rowSpans[index] = 1;
      } else {
        count += 1;
        rowSpans[index - count + 1] = count;
      }
    });

    return rowSpans;
  };

  // Calculate row spans for `mainComponent` and `year`
  const mainComponentRowSpan = calculateRowSpan(data, "mainComponent");
  const yearRowSpan = calculateRowSpan(data, "year");

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow>
                {yearRowSpan[index] && (
                  <TableCell
                    align="center"
                    rowSpan={yearRowSpan[index]}
                    {...styles("cell", { width: "100px" })}
                  >
                    {row.year}
                  </TableCell>
                )}
                {mainComponentRowSpan[index] && (
                  <TableCell
                    align="center"
                    rowSpan={mainComponentRowSpan[index]}
                    {...styles("cell", { width: "200px" })}
                  >
                    {row.mainComponent}
                  </TableCell>
                )}
                <TableCell align="center" {...styles("cell")}>
                  {row?.subComponent}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.perticularOfItem}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.unit}
                </TableCell>
                {/* <TableCell align="center" {...styles("cell")}>
                  {row.costPerUnit}
                </TableCell> */}
                <TableCell align="center" {...styles("cell")}>
                  {row.numberOfUnits}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.costRequired}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
