import { routes } from "./routes.module";

const routesData = {
  defaultRoutes: [
    routes.home,
    routes.notFound,
    routes.userProfile,
    routes.myProfile,
    routes.users,
    routes.projectDetailView,
    routes.demandDraft,
    routes.viewDemand,
    routes.registeredLands,
    routes.userDetails,
    routes.landOwnershipDetails,
    routes.implementingAgency,
    routes.marketWatch,
    routes.changePassword,
    routes.fundTransfer,
    routes.fundApplicationDetails,
    routes.costEstimateDetails,
    routes.progressDetails,
  ],

  authRoutes: [routes.signIn, routes.login, routes.forgetPassword],
  guestRoutes: [],
};

export default routesData;
