import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import {
  Button,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import Snackbar from "src/components/common/Snackbar";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import { SnackbarType } from "src/types/common";
import { getLandDetailsById, viewDemandDraft } from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "../styles";
import { useAppSelector } from "src/redux/store";
import { formatToIndianCurrency } from "src/utils/helper";

const DemandNoteView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const id = location?.state?.id || "";

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [showSnackbar, setShowSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
  });

  const { mutate } = useMutation(
    "changeStatus",
    ({
      id,
      data,
    }: {
      id: string;
      data: {
        status: string;
        remark: string;
        paymentAcknowledgmentReceiptId: string;
      };
    }) =>
      viewDemandDraft({
        id,
        data,
      }),
    {
      onSuccess: () => {
        navigate(path.home);
      },
      onError: () => {},
    }
  );

  const { data: landData } = useQuery(
    ["landDetails", id],
    () => getLandDetailsById(id),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res.data);
      },
      onError: (req: AxiosError) => {},
      refetchOnMount: true,
      enabled: !!id,
    }
  );

  const {
    control,
    watch,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      demandNote: null,
    },
  });

  const userDetails = useAppSelector((state: any) => state.root.user);
  const role = userDetails?.data?.role;

  const docId = getValues("paymentAcknowledgmentReceiptId");

  return (
    <Box component="form">
      <DashboardWrapper
        title="Payment Verification"
        customStyles={{
          container: { position: "relative" },
          children: { paddingBottom: "80px" },
        }}
        backPath={`/`}
        showBackBtn
      >
        {/* <Box {...styles("projectName")}>
          Cost Estimate ID : {landData?.data?.id}
        </Box> */}

        <Box {...styles("formWrapper")}>
          <Box {...styles("heading")}>
            Demand Note Details (Requested To Entity)
          </Box>

          <Box
            component="a"
            href={`${path.landOwnershipDetails}?${createSearchParams({
              id: landData?.data?.id,
            })}`}
            sx={{ ...defaultStyles.renderButtonText2 }}
          >
            <ViewForm label="Cost Estimate ID" value={landData?.data?.id} />
          </Box>
          <ViewForm
            label="Eco-Restoration Cost"
            value={`${formatToIndianCurrency(landData?.data?.demandNote)}`}
          />
          <ViewForm
            label="Admin Charges"
            value={`${formatToIndianCurrency(landData?.data?.icfreCharges)}`}
          />
          <ViewForm
            label="Demand Note Amount"
            value={`${formatToIndianCurrency(
              +`${Number(landData?.data?.demandNote)}` +
                +`${Number(landData?.data?.icfreCharges)}`
            )}`}
          />
        </Box>

        <Box {...styles("formWrapper")}>
          <Box {...styles("heading")}>Transaction Details</Box>
          <ViewForm
            label="Eco-Restoration Cost"
            value={formatToIndianCurrency(landData?.data?.ecoRestorationCost)}
          />
          <ViewForm
            label="TDS On Eco-Restoration Cost"
            value={formatToIndianCurrency(
              landData?.data?.ecoRestorationCostTDS
            )}
          />
          <ViewForm
            label="Admin Changes"
            value={formatToIndianCurrency(landData?.data?.adminCharges)}
          />
          <ViewForm
            label="TDS On Admin Changes"
            value={formatToIndianCurrency(landData?.data?.adminChargesTDS)}
          />
          <ViewForm
            label="Total TDS Amount "
            value={formatToIndianCurrency(
              +landData?.data?.ecoRestorationCostTDS +
                +landData?.data?.adminChargesTDS
            )}
          />
          <ViewForm
            label="Amount Paid By Entity"
            value={formatToIndianCurrency(landData?.data?.amountPaid)}
          />
          <ViewForm
            label="Account Number"
            value={landData?.data?.bankDetail?.accountNumber}
          />
          <ViewForm
            label="Bank Name"
            value={landData?.data?.bankDetail?.bankName}
          />
          <ViewForm
            label="Branch Name"
            value={landData?.data?.bankDetail?.branchLocation}
          />
          <ViewForm
            label="IFSC Code"
            value={landData?.data?.bankDetail?.ifscCode}
          />
          <ViewForm
            label="Transaction Date"
            value={landData?.data?.transactionDate}
          />
          <ViewForm
            label="Transaction Type"
            value={landData?.data?.transactionType}
          />
          <ViewForm
            label="Transaction Number"
            value={landData?.data?.transactionNumber}
          />
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Payment Receipt </Box>
            <Box {...styles("documentGroup")}>
              <ViewImages
                fileUrl={landData?.data?.paymentImageUrl}
                label={"Payment Receipt "}
              />
            </Box>
          </Box>
          <ViewForm
            label="Additional Information"
            value={landData?.data?.additionalInfo}
          />
        </Box>
        {role !== "Approver-view-only" && (
          <>
            <Box sx={{ width: "60%" }}>
              <FileUpload
                // accept="image/jpeg, image/png, image/webp, image/jpg"
                name="paymentAcknowledgmentReceiptId"
                label="Upload Payment Receipt"
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                maxLimit={1}
                multiple={true}
                customStyles={{
                  label: {
                    whiteSpace: "wrap",
                  },
                }}
                supportedFormatMessage="Upload Payment Receipt"
                trigger={trigger}
                required={true}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 1;
                    },
                  },
                }}
                dragDropHeading=""
              />
            </Box>
            <Input
              name="remarks"
              label="Admin Remark on Payment"
              control={control}
              errors={errors}
              multiline
              height="360px"
              customStyles={{
                input: {
                  height: "auto",
                  fontFamily: "Poppins",
                  width: { sm: "100%", lg: "80%" },
                },
              }}
            />
          </>
        )}
        {role !== "Approver-view-only" && (
          <Box {...styles("btnWrapper")}>
            {/* <Button
              text="Reject"
              variant="outlined"
              color="error"
              onClick={() => {
                mutate({
                  id,
                  data: {
                    status: "REJECTED",
                    remark: watch("remarks"),
                  },
                });
                navigate(path.home);
              }}
              customStyles={{
                root: {
                  fontSize: "18px",
                  color: "error.main",
                  borderColor: "error.main",
                },
              }}
            /> */}
            <Button
              text="Approve"
              type="submit"
              onClick={() => {
                mutate({
                  id,
                  data: {
                    status: "APPROVED",
                    remark: watch("remarks"),
                    paymentAcknowledgmentReceiptId: docId[0]?.key,
                  },
                });
                navigate(path.home);
              }}
              customStyles={{
                root: {
                  fontSize: "16px",
                },
              }}
              disabled={!isValid}
            />
          </Box>
        )}
      </DashboardWrapper>
      <Snackbar
        open={!!showSnackbar.message}
        message={showSnackbar.message}
        severity={showSnackbar.severity}
        resetSnackbar={() => setShowSnackbar({ ...showSnackbar, message: "" })}
      />
    </Box>
  );
};

export default DemandNoteView;
