import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getImageById } from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "./styles";

type ViewImageProps = {
  label?: string;
  imageId?: string;
  fileUrl?: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const ViewImages = ({
  label = "",
  imageId = "",
  customStyles,
  fileUrl = "",
}: ViewImageProps) => {
  const defaultSrc = "/assets/images/NoImageFoundIcon.png";
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [imageSrc, setImageSrc] = useState("");

  const { isLoading } = useQuery(
    ["getImage", imageId],
    () => getImageById(imageId),
    {
      onSuccess: (res: AxiosResponse) => {
        setImageSrc(res?.data?.fileUrl);
      },
      onError: (err: AxiosError) => {
        setImageSrc(defaultSrc);
      },
      enabled: !!imageId,
      // refetchOnMount: true,
    }
  );

  return (
    <Box {...styles("wrapper")}>
      {!!label && <Box {...styles("label")}>{label}</Box>}
      <Box
        component="a"
        href={!!imageId ? imageSrc : fileUrl}
        download
        target="_blank"
      >
        <Box
          component="img"
          src={!!imageId ? imageSrc : fileUrl}
          alt={fileUrl}
          {...styles("img")}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const target = e.target as HTMLImageElement;
            target.src = "/assets//svg/receipt.svg";
          }}
        />
      </Box>
    </Box>
  );
};

export default ViewImages;
