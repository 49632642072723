import { Box } from "@mui/material";
import React from "react";
import defaultStyles, { StylesClasses } from "./styles";
import { CustomStyles, getStyles } from "src/styles/theme";
import { Button } from "../FormComponents";

type EmptyViewProps = {
  imgSrc?: string;
  heading: string;
  subheading?: string;
  onClick?: () => void;
  showBtn?: boolean;
  customStyles?: CustomStyles<StylesClasses>;
  btnLabel?: string;
};

const EmptyView = ({
  imgSrc = "assets/images/NoImageFoundIcon.png",
  heading,
  subheading = "",
  onClick = () => null,
  showBtn = true,
  btnLabel = "",
  customStyles,
}: EmptyViewProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("noRows")}>
      <Box component={"img"} src={imgSrc} />
      <Box {...styles("noProject")}>{heading}</Box>
      {!!subheading && <Box {...styles("desc")}>{subheading}</Box>}
      {!!btnLabel && (
        <Button
          text="Register New Green Credit Activity"
          src="/assets/svg/roundPlusIcon.svg"
          onClick={onClick}
          customStyles={{ root: defaultStyles.dimension }}
        />
      )}
    </Box>
  );
};

export default EmptyView;
