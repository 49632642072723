import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
type optionProp = {
  label: string;
  value: boolean | string;
  isActive?: boolean;
};

type MenuOptionProps = {
  options: optionProp[];
  customStyles?: CustomStyles<StylesClasses>;
  handleMenuClick?: (item: optionProp) => void;
};

export const MenuOption = ({
  options,
  customStyles,
  handleMenuClick,
}: MenuOptionProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApprove = () => {};

  const handleReject = () => {};

  return (
    <>
      <Box {...styles("wrapper")}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          {...styles("name")}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          {...styles("menuOption")}
        >
          {options.map((val) => {
            return (
              <MenuItem
                key={val.label}
                onClick={handleClose}
                {...styles("menuItem")}
              >
                {val.isActive ? (
                  <Box
                    {...styles("Approve")}
                    onClick={() => handleMenuClick(val)}
                  >
                    {val.label}
                  </Box>
                ) : (
                  <Box
                    {...styles("Reject")}
                    onClick={() => handleMenuClick(val)}
                  >
                    {val.label}
                  </Box>
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </>
  );
};
