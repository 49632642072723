import DashboardWrapper from "src/components/common/DashboardWrapper";
import HorizontalTabs from "src/components/common/HorizontalTabs";
import EcoRestorationDashboard from "./ecoRestorationDashboard";
import CostEstimateDashboard from "./costEstimateDashboard";
import Entity from "./entityDashoard";
import SelectedLandDashboard from "./selectedLand";
import FundApplicationDashboard from "./fundApplicationDashboard";

const MarketWatch = () => {
  return (
    <DashboardWrapper title="Dashboard" subTitle="">
      <HorizontalTabs
        tabHeaders={[
          { label: "Eco-Restoration Blocks", value: "page1" },
          { label: "Available/Selected Blocks", value: "SelectedLand" },
          { label: "Cost Estimates", value: "page2" },
          { label: "Entity Details", value: "Entity" },
          { label: "Fund Application", value: "FundApplication" },
        ]}
        components={[
          <EcoRestorationDashboard />,
          <SelectedLandDashboard />,
          <CostEstimateDashboard />,
          <Entity />,
          <FundApplicationDashboard />,
        ]}
      />
    </DashboardWrapper>
  );
};

export default MarketWatch;
