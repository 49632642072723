import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    position: "relative",
  },

  menuOptionIcon: {
    fontSize: "35px",
  },

  label: {},

  inputLabel: {},

  errormsg: {
    mb: "5px",
  },
});

export default styles;
