import { ProjectType } from "./common";

export const tableHead = [
  {
    name: "Project Name",
    isSortable: false,
    type: "string",
  },
  {
    name: "STATUS",
    isSortable: false,
    type: "strasdasding",
  },
  {
    name: "ACTIVITY  INITIATION DATE",
    isSortable: false,
    type: "strdasdasing",
  },
  {
    name: "Total duration",
    isSortable: false,
    type: "strdasdasing",
  },
  {
    name: "Total CREDIT to be disbursed",
    isSortable: false,
    type: "stadsaasdring",
  },

  {
    name: "",
    isSortable: false,
    type: "stadsaasdring",
  },
];

export const projectTypeOptions = [
  {
    title: "Water Credits",
    description: "Lorem ipsum dolor sit amet consectetur.",
    img: "assets/images/waterProject.png",
    name: ProjectType.WATER_CONSERVATION,
  },
  {
    title: "Tree Plantation Credits",
    description: "Lorem ipsum dolor sit amet consectetur.",
    img: "assets/images/platation.png",
    name: ProjectType.TREE_PLANTATION,
  },
];

export const treeTypes = [
  {
    label: "timber Tree",
    value: "timber tree",
  },
  {
    label: "Fruits Tree",
    value: "Fruits Tree",
  },
  {
    label: "Bamboo Tree",
    value: "Bamboo Tree",
  },
];

export const activities = [
  {
    label: "timber Tree",
    value: "timber tree",
  },
  {
    label: "Fruits Tree",
    value: "Fruits Tree",
  },
  {
    label: "Bamboo Tree",
    value: "Bamboo Tree",
  },
];

export const agencyType = [
  {
    label: "Individual",
    value: "Individual",
  },
  {
    label: "Entity",
    value: "Entity",
  },
];

export const plantationTypes = [
  {
    label: "timber Tree",
    value: "timber tree",
  },
  {
    label: "Fruits Tree",
    value: "Fruits Tree",
  },
  {
    label: "Bamboo Tree",
    value: "Bamboo Tree",
  },
];

export const treeSizes = [
  {
    label: "Small",
    value: "Small",
  },
  {
    label: "Medium",
    value: "Medium",
  },
];

export const treeSubHeaders = [
  {
    title: "Entity Details",
    details:
      "Enter Entity/Affiliation details of individual/company/ cooperative details",
  },
  {
    title: "Project Area Location Details",
    details: "Enter Project Area Location Details",
  },
  {
    title: "Executing Agency Details",
    details: "Enter Project Executing Agency Details",
  },
  {
    title: "Plot/ Site/ Area Type Details",
    details: "Enter Plot/ Site/ Area Type Details",
  },
  {
    title: "Bank Details",
    details: "Enter Bank Account Details",
  },
  {
    title: "Green Credit Account Details",
    details: "Pay your fees for Project Creation",
  },
  {
    title: "Self Declaration",
    details: "Self Declaration Acknowledgement",
  },
];

export const waterSubHeaders = [
  {
    title: "Entity Details",
    details:
      "Enter Entity/Affiliation details of individual/company/ cooperative details",
  },
  {
    title: "Project Area Location Details",
    details: "Enter Project Area Location Details",
  },
  {
    title: "Executing Agency Details",
    details: "Enter Project Executing Agency Details",
  },
  {
    title: "Plot/ Site/ Area Type Details",
    details: "Enter Plot/ Site/ Area Type Details",
  },
  {
    title: "Structure/ Project DPR Details",
    details: "Enter Structure/ Project DPR Details",
  },
  {
    title: "Bank Details",
    details: "Enter Bank Account Details",
  },
  {
    title: "Green Credit Account Details",
    details: "Pay your fees for Project Creation",
  },
  {
    title: "Self Declaration",
    details: "Self Declaration Acknowledgement",
  },
];

export type MyProfileType = {
  phoneNumber: {
    countryCode: string;
    number: string;
  };
  aadhaarNumber: string;
  documentType: string;
  firstName: string;
  email: string;
  role: string;
  devType: string;
  documentTypes: any[];
  aadhaarCardFront: any[];
  aadhaarCardBack: any[];
  userProfileType: string;
  authName: string;
  authDesig: string;
  registration: any[];
  currentAddress: any;
  entityType: string;
  permanentAddress: any;
};

export const myProfileValue = {
  phoneNumber: {
    countryCode: "",
    number: "",
  },
  authName: "",
  authDesig: "",
  userProfileType: "",
  registration: [],

  documentTypes: [],
  aadhaarNumber: "",
  aadhaarCardFront: [],
  aadhaarCardBack: [],
  documentType: "",
  firstName: "",
  email: "",
  role: "",
  devType: "",
  currentAddress: {
    addressLine1: "",
    city: "",
    district: "",
    state: "",
    zipCode: "",
  },
  entityType: "",
  permanentAddress: {
    addressLine1: "",
    city: "",
    district: "",
    state: "",
    zipCode: "",
  },
};
