import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  projectName: {
    fontSize: "28px",
    fontWeight: "600",
  },

  heading: {
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: "15px",
    paddingBottom: "10px",
    color: "primary.main",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "primary.main",
  },

  formWrapper: {
    margin: "20px 0",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "15px 20px",
    borderRadius: "15px",
    maxWidth: { xs: "100%", lg: "60%" },
  },

  downloadBtn: {
    color: "primary.main",
    display: "flex",
    justifyContent: "flex-end",
    textDecoration: "none",
    alignItems: "center",
    fontSize: "18px",
    gap: "3px",
    position: "absolute",
    right: "20px",
  },

  group: {
    margin: "5px 0 10px 0px",
  },

  subHeading: {
    fontSize: "18px",
    fontWeight: "500",
  },

  btnWrapper: {
    display: "flex",
    gap: "5%",
    position: "absolute",
    bottom: "20px",
    right: "20px",
    fontSize: "16px",
  },

  imageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2%",
    margin: "15px 0",
  },

  loading: {
    display: "flex",
    justifyContent: "center",
  },

  documentGroup: {
    display: "flex",
    gap: "2%",
    margin: "10px 0",
  },

  label: {
    fontSize: "16px",
    fontWeight: "600",
    marginRight: "4px",
  },
  imagesWrapper: {
    border: "1px solid #888",
    padding: "10px",
    borderRadius: "8px",
    margin: "10px 0",
  },
  imgContainer: {
    objectFit: "contain",
    display: "flex",
    justifyContent: "center",
  },
  selfDeclaration: {
    fontWeight: 500,
    fontSize: "16px",
  },
  infoContainer: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row-reverse",
    marginBottom: "20px",
  },

  input: {
    flexBasis: { xs: "100%", sm: "75%", lg: "34%" },
    margin: "0 0 24px 0",
  },
  renderButtonText2: {
    display: "block",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "600",
    color: "#36B1A0",
    textDecoration: "none",
  },
});

export default styles;
