import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    "& .stickyCol": {
      position: "sticky",
      right: 0,
      background: "white",
      borderLeft: "1px solid #80808057",
      "& .MuiDataGrid-main": { overflowX: "hidden !important" },
      "& .MuiDataGrid-viewport": { overflowY: "scroll" },
      "& .MuiDataGrid-colCell": { backgroundColor: "#f0f0f0" },
    },
  },

  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  button: {
    width: "5%",
    border: "1px solid blue",
    margin: "1%",
    "&:hover": {
      backgroundColor: "blue",
      color: "white",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  renderButtonText: {
    display: "block",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "600",
    color: "#36B1A0",
    textDecoration: "none",
  },
  searchHeader: {
    display: "flex",
    // minWidth:"100%",
    alignItems: "baseline",
    gap: "3%",
    padding: "10px 1%",
    background: "white",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
});

export default styles;
