import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    height: "100vh",
    p: {
      xs: "80px 5% 84px",
      sm: "130px 15% 206px",
      lg: "120px 6% 91px",
    },
    width: "100%",
    /*  backgroundImage:
      " linear-gradient(105.44deg, #40732F 37.97%, #00B449 120.5%);", */
    backgroundColor: "#000000E6",
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "center",
    alignItems: "center",
    gap: {
      tablet: "70px",
      laptop: 0,
    },
  },

  leftSection: {
    flexBasis: {
      laptop: "50%",
    },
    boxSizing: "border-box",
    minWidth: {
      laptop: "575px",
    },
    margin: {
      xs: "5%",
      sm: 0,
      lg: "3%",
    },
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  rightSection: {
    flexBasis: {
      laptop: "50%",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2em",
  },

  errorWrapper: {
    position: "absolute",
    top: "auto",
    left: 0,
    bottom: {
      xs: "1%",
      sm: "5%",
      lg: "5%",
    },
    right: 0,
  },

  error: {
    fontSize: { xs: "30px", sm: "45px", lg: "64px" },
    fontWeight: "600",
    color: "white",
    textAlign: "center",
  },

  pageNotFound: {
    fontSize: { xs: "26px", sm: "30px", lg: "36px" },
    fontWeight: "600",
    color: "white",
    textAlign: "center",
  },

  content: {
    fontSize: "22px",
    fontWeight: "600",
    color: "white",
    textAlign: { xs: "center", sm: "left", lg: "left" },
    mt: "1em",
  },

  image: {
    objectFit: "cover",
    width: "100%",
  },

  colorAccent: {
    pl: "8px",
    cursor: "pointer",
    textDecoration: "none",
  },
});

export default styles;
