import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { stepperConfig } from "src/constants/common";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { Stepper } from "src/types/common";

interface StepperType {
  userType: string;
  devType: string;
}

interface InitialState {
  stepperType: StepperType;
  stepperData: Stepper[];
}

const initialState: InitialState = {
  stepperType: { userType: "", devType: "" },
  stepperData: [
    {
      id: 1,
      isCompleted: false,
      name: "User Type Details",
      isActive: true,
      route: "path.onboarding",
    },
    {
      id: 2,
      isCompleted: false,
      name: "Personal Details",
      isActive: false,
      route: "path.personalDetails",
    },
    {
      id: 3,
      isCompleted: false,
      name: "Aadhaar Verification",
      isActive: false,
      route: "path.aadhaarVerification",
    },
    {
      id: 4,
      isCompleted: false,
      name: "Address Details",
      isActive: false,
      route: "path.addressDetails",
    },
  ],
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<StepperType>) => {
      const { userType, devType } = action.payload;

      state.stepperData = stepperConfig.filter((item) => {
        return (
          item.devType.toLowerCase() === devType.toLowerCase() &&
          item.userType.toLowerCase() === userType.toLowerCase()
        );
      })[0].stepperData;
    },

    updateStepper: (state, action: PayloadAction<{ id: number }>) => {
      let stepper = state.stepperData;

      const currStep = stepper.findIndex(
        (item) => item.id === action.payload.id
      );

      stepper[currStep] = {
        ...stepper[currStep],
        isCompleted: true,
        isActive: false,
      };

      if (action.payload.id + 1 <= stepper.length) {
        stepper[currStep + 1] = {
          ...stepper[currStep + 1],
          isCompleted: false,
          isActive: true,
        };
      }

      state.stepperData = stepper;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFormAction, (state, _action) => {
      state.stepperData = initialState.stepperData;
    });
  },
});

export const { updateStepper, setConfig } = stepperSlice.actions;

export default stepperSlice.reducer;
