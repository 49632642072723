import { Box, Typography } from "@mui/material";
import { getStyles, CustomStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import { Button } from "src/components/common/FormComponents";
import { To, useNavigate } from "react-router-dom";

type TitleBoxProps = {
  customStyles?: CustomStyles<StylesClasses>;
  title?: string;
  subTitle?: string;
  buttonIcon?: string;
  buttonText?: string;
  showBtn?: boolean;
  showBackBtn?: boolean;
  onClick?: () => void;
  backPath?: To;
};

export const TitleBox = ({
  title,
  subTitle,
  buttonIcon,
  buttonText,
  customStyles,
  showBtn,
  onClick,
  showBackBtn,
  backPath,
}: TitleBoxProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  const navigate = useNavigate();

  return (
    <Box {...styles("TitleBoxContainer")}>
      <Box>
        <Box {...styles("backBtnBox")}>
          {showBackBtn && (
            <Box
              onClick={() => {
                backPath ? navigate(backPath) : navigate(-1);
              }}
            >
              <Box
                {...styles("backBtn")}
                component={"img"}
                src="/assets/images/ArrowBackIcon.png"
              />
            </Box>
          )}

          <Typography {...styles("title")}>{title}</Typography>
        </Box>
        {subTitle && (
          <Typography {...styles("subTitle")}>{subTitle}</Typography>
        )}
      </Box>
      {!!buttonText && showBtn && (
        <Button
          text={buttonText}
          src={buttonIcon}
          onClick={onClick}
          customStyles={{ root: defaultStyles.root }}
        />
      )}
    </Box>
  );
};

export default TitleBox;
