import { Box, Button, Collapse, Typography } from "@mui/material";
import { useQuery } from "react-query";
import StatsCard from "src/components/common/StatsCard";

import defaultStyles, { StylesClasses } from "../styles";
import { getStyles } from "src/styles/theme";
import {
  getLandDashboardDetails,
  getTableDashboardDetails,
} from "src/utils/api/dashboard";
import { DashBoardTable } from "src/components/common/DashBoardTable";
import {
  TableformatToIndianCurrency,
  downloadSheet,
  exportTableToXlsx,
  formatData,
  formatDataForBarChart,
  formatToIndianCurrency,
  fundApplicationExportTableToXlsx,
} from "src/utils/helper";
import Fallback from "src/components/common/Fallback";
import { useEffect, useState } from "react";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import BarChartComponent from "src/components/common/BarComponent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RenderTable from "../../Home/RenderTable";
import { costEstimateDashboardtableColumns } from "src/constants/tableColumns";
import { AxiosResponse } from "axios";
import { FundApplicationDashBoardTable } from "src/components/common/DashBoardTable/fundApplicationDashboard";

const FundApplicationDashboard = () => {
  const [isTabLoading, setIsTabLoading] = useState(true);

  const [states, setStates] = useState<any>([]);

  const [tableRows, setTableRows] = useState([]);

  const [filtersState, setFiltersState] = useState([]);

  const [queryParams, setQueryParams] = useState<{
    projectStateIds?: string[];
    type: string;
  }>({
    projectStateIds: undefined,
    type: "registrationAndApplicationStatus",
  });

  const [boxQueryParams, setBoxQueryParams] = useState<{
    projectStateIds?: string[];
    type: string;
  }>({
    projectStateIds: undefined,
    type: "registrationAndApplicationStatusBoxes",
  });

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
  });

  const { data: dashboard, isLoading: dashboardLoading } = useQuery(
    ["fundApplicationDashboard", boxQueryParams],
    () => getTableDashboardDetails(boxQueryParams),
    {
      onSuccess: () => {},
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const { data: tableDashboard, isLoading } = useQuery(
    ["fundApplication", queryParams],
    () => getTableDashboardDetails(queryParams),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res?.data?.stats.map((items, idx) => ({
            id: idx,
            sno: idx + 1,
            state: items.state,
            totalAreaSelected: items.totalAreaSelected,
            totalNumberSelected: items.totalNumberSelected,
            totalNumberApproved: items.totalNumberApproved,
            totalAreaApproved: items.totalAreaApproved,
            totalAreaDemandNoteRaised: items.totalAreaDemandNoteRaised,
            totalNumberDemandNoteRaised: items.totalNumberDemandNoteRaised,
            totalAmountDemandNoteRaised: TableformatToIndianCurrency(
              items.totalAmountDemandNoteRaised
            ),
            totalAreaPaymentReceived: items.totalAreaPaymentReceived,
            totalNumberPaymentReceived: items.totalNumberPaymentReceived,
            totalAmountPaymentReceived: TableformatToIndianCurrency(
              items.totalAmountPaymentReceived
            ),
            totalAreaFundApplicationPending:
              items.totalAreaFundApplicationPending,
            totalNumberFundApplicationPending:
              items.totalNumberFundApplicationPending,
            totalAmountFundApplicationPending: TableformatToIndianCurrency(
              items.totalAmountFundApplicationPending
            ),
            totalAreaFundApplicationReceived:
              items.totalAreaFundApplicationReceived,
            totalNumberFundApplicationReceived:
              items.totalNumberFundApplicationReceived,
            totalAmountFundApplicationReceived: TableformatToIndianCurrency(
              items.totalAmountFundApplicationReceived
            ),
            totalAreaFirstYearPaymentReleased:
              items.totalAreaFirstYearPaymentReleased,
            totalNumberFirstYearPaymentReleased:
              items.totalNumberFirstYearPaymentReleased,
            totalAmountFirstYearPaymentReleased: TableformatToIndianCurrency(
              items.totalAmountFirstYearPaymentReleased
            ),
          }))
        );
        setIsTabLoading(false);
      },
      onError: () => {},
      refetchOnMount: true,
    }
  );

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const formattedData = formatDataForBarChart(dashboard?.data?.stats);

  const dashboardTableData: DashBoardTable = {
    customTopheader: [
      { title: "", size: 2 },
      { title: "Approved Eco-Restoration Blocks", size: 2 },
      { title: "Selected Eco-Restoration Blocks", size: 2 },
      { title: "Demand Note Raised", size: 3 },
      { title: "Payment Received", size: 3 },
      { title: "Fund Application Pending for 2024-25", size: 2 },
      { title: "Fund Application Received for 2024-25", size: 3 },
      { title: "First Year Payment Released", size: 3 },
      { title: "Balance Amount with Admin", size: 1 },
    ],
    topHeader: [
      "",
      "Approved Eco-Restoration Blocks",
      "Selected Eco-Restoration Blocks",
      "Demand Note Raised",
      "Payment Received",
      "Fund Application Pending for 2024-25",
      "Fund Application Received for 2024-25",
      "First Year Payment Released",
      "Balance Amount with Admin",
    ],
    header: [
      "S.No",
      "State",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",

      "No",
      "Area (ha)",
      "Amount (₹)",

      "No",
      "Area (ha)",
      "Amount (₹)",

      "No",
      "Area (ha)",

      "No",
      "Area (ha)",
      "Amount (₹)",

      "No",
      "Area (ha)",
      "Amount (₹)",

      "Amount (₹)",
    ],
    data: tableRows,
  };
  const styles = getStyles(defaultStyles);

  useEffect(() => {
    setFiltersState(dashboard?.data?.state);
  }, [dashboard]);

  useEffect(() => {
    setIsTabLoading(true);
  }, []);

  const [tableState, setTableState] = useState(false);

  return (
    <>
      <Box {...styles("topHeader")}>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <MultiSelect
            options={states}
            onChange={(e: any) => {
              setFilterKey((prevFilter) => {
                const newState = {
                  ...prevFilter,
                  projectStateIds: e.length ? e : null,
                };

                setQueryParams({
                  ...queryParams,
                  projectStateIds: newState?.projectStateIds,
                });

                setBoxQueryParams({
                  ...boxQueryParams,
                  projectStateIds: newState?.projectStateIds,
                });

                return newState;
              });
            }}
          />
        </Box>
        {!dashboardLoading ? (
          <Box sx={{ width: "75vw", marginBottom: "20px", marginTop: "80px" }}>
            <Box sx={{ width: "80%", marginRight: "80px" }}>
              <BarChartComponent
                data={formattedData}
                label={
                  "Status of Payment and Fund Application of Eco-Restoration Blocks"
                }
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box {...styles("statsCardWrapper", { marginTop: "20px" })}>
          {dashboard?.data?.stats?.map((stat, index) => (
            <StatsCard
              key={index}
              title={`${stat?.description?.toUpperCase()}`}
              value={`${stat?.number}`}
              area={stat?.totalArea}
              customStyles={{ valueCard: styles("statsCard").sx }}
              link={""}
            />
          ))}
        </Box>
      </Box>
      {isLoading || isTabLoading ? (
        <Fallback />
      ) : (
        <Box {...styles("dashboardTableWrapper")}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" {...styles("heading")}>
              Status of Payment and Fund Application
            </Typography>
            <Button
              variant="outlined"
              onClick={() => setTableState(!tableState)}
            >
              {tableState ? (
                <>
                  Compress
                  <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
                </>
              ) : (
                <>
                  Expand
                  <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
                </>
              )}
            </Button>
          </Box>
          <Collapse in={tableState}>
            <Button
              variant="outlined"
              onClick={() =>
                fundApplicationExportTableToXlsx(
                  dashboardTableData.data,
                  dashboardTableData.header,
                  dashboardTableData.customTopheader
                )
              }
              sx={{ float: "right", marginBottom: "20px" }}
            >
              Export
            </Button>
            <FundApplicationDashBoardTable {...dashboardTableData} />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default FundApplicationDashboard;
