import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents/Buttons";
import { getProgressDetails } from "src/utils/api/dashboard";
import { dateAndTimeStamp, downloadSheet, formatINR } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "./styles";
import { path } from "src/constants/path";
import SearchBar from "src/components/common/SearchField";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import { getStyles } from "src/styles/theme";

const ProgressDetails = () => {
  const navigate = useNavigate();
  const [tableRows, setTableRows] = useState([]);

  const [states, setStates] = useState<any>([]);

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [queryParams, setQueryParams] = useState<{
    offset: number;
    limit: number;
    download: boolean;
    sortBy: string;
    searchText: string;
    projectStateIds?: string[];
  }>({
    offset: 0,
    limit: 300,
    download: false,
    sortBy: "updated_at:DESC",
    searchText: "",
    projectStateIds: undefined,
  });

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
  });

  const { isLoading, isFetching } = useQuery(
    ["users", queryParams],
    () =>
      getProgressDetails({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const row = res?.data?.data?.rows.map((row) => ({
          sno: row?.serialNo,
          id: row?.landOwnership?.id,
          costEstimateID: row?.landOwnership.id,
          registrationNo: row?.landOwnership.projectDetail.registrationNo,
          entityName: row?.landOwnership.user.firstName,
          state: row?.landOwnership?.projectDetail?.address?.stateDetail?.name,
          division: row?.landOwnership.projectDetail.landSpecification.division,
          totalArea: `${row?.landOwnership.netPlantationArea}`,
          ecoRestorationAmount: formatINR(
            row?.landOwnership?.demandNote ?? "-"
          ),
          created_at: dateAndTimeStamp(row?.landOwnership.created_at, "IST"),
          updated_at: dateAndTimeStamp(row?.landOwnership.updated_at, "IST"),
        }));

        if (queryParams.download) {
          const exportData = row.map((item) => {
            return {
              "S.No": item?.sno,
              "FA-ID": item?.id,
              "Registration No": item?.registrationNo,
              "Entity Name": item.entityName,
              State: item?.state,
              Division: item?.division,
              "Net Planned Area (Ha)": item?.totalArea,
              "Eco-Restoration Cost (₹)": item?.ecoRestorationAmount,
              "Created At": item?.created_at,
              "Updated At": item?.updated_at,
            };
          });
          downloadSheet(exportData);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setTableRows(row);
        }
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const RenderEPID = (params) => {
    return (
      <>
        <Box
          component="a"
          href={`${path.costEstimateDetails}?${createSearchParams({
            id: params?.row?.costEstimateID,
          })}`}
          sx={{ ...defaultStyles.renderButtonText2 }}
        >{`${params?.row?.costEstimateID}`}</Box>
      </>
    );
  };

  const tableColumns: any = [
    {
      field: "sno",
      headerName: "S.No",
      width: 90,
      sortable: false,
    },
    {
      field: "costEstimateID",
      headerName: "Cost Estimate ID",
      width: 220,
      sortable: false,
      renderCell: RenderEPID,
    },
    {
      field: "registrationNo",
      headerName: "Reg.No",
      width: 100,
      sortable: false,
    },
    {
      field: "entityName",
      headerName: "Entity Name",
      width: 150,
      sortable: true,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      sortable: true,
    },
    {
      field: "division",
      headerName: "Division",
      width: 150,
      sortable: true,
      userType: ["INDIVIDUAL"],
      tab: [],
    },
    {
      field: "totalArea",
      headerName: "Net Planned Area(Ha)",
      width: 150,
      sortable: false,
    },
    {
      field: "ecoRestorationAmount",
      headerName: "Eco-Restoration Cost(₹)",
      width: 200,
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      sortable: true,
    },
    {
      field: "updated_at",
      headerName: "UPDATED AT",
      width: 150,
      sortable: true,
    },
  ];

  const exportToExcelEntity = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const handleSort = (sortByField) => {
    setQueryParams({
      ...queryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  return (
    <DashboardWrapper
      title="Progress Details"
      buttonIcon="/assets/svg/roundPlusIcon.svg"
      buttonText=""
      onButtonClick={() => {}}
      showBtn={!!tableRows.length}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: "2%",
            padding: "10px 1%",
            background: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <SearchBar
            getText={(e) => {
              setQueryParams((a) => {
                return { ...queryParams, searchText: `${e}` };
              });
            }}
            customStyles={{
              wrapper: {
                width: "80%",
              },
            }}
          />
          <Box sx={{ display: "flex", gap: "8px" }}>
            <MultiSelect
              options={states}
              onChange={(e: any) => {
                setFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    projectStateIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    projectStateIds: newState?.projectStateIds,
                  });

                  return newState;
                });
              }}
            />
          </Box>
          <Button onClick={exportToExcelEntity} text={"Export"} />
        </Box>
        {/* Data Grid */}
        {isLoading || isFetching ? (
          <Box {...styles("flexContainer")}>
            <CircularProgress />
          </Box>
        ) : tableRows.length > 0 ? (
          <DataGrid
            rows={tableRows}
            columns={tableColumns}
            rowCount={tableRows.length}
            rowsPerPageOptions={[10]}
            sortingMode="server"
            onSortModelChange={handleSort}
            paginationMode="client"
            pageSize={50}
            columnBuffer={20}
            loading={isLoading}
            getRowId={(row) => `${row.id}-${row.userName}`}
          />
        ) : (
          <EmptyView
            heading="No Entry Found"
            subheading=""
            onClick={() => {}}
          />
        )}
      </Box>
    </DashboardWrapper>
  );
};

export default ProgressDetails;
