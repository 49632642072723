import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "src/types/common";

type AllProjectsData = {
  count: number;
  rows: Project[];
  refetchProjects: boolean;
};

const initialState: AllProjectsData = {
  rows: [],
  count: 0,
  refetchProjects: true,
};
const allProjectsDetails = createSlice({
  name: "allProjectsDetailsSlice",
  initialState,
  reducers: {
    getAllProjectsData: (state, action) => {
      state.rows = action.payload.rows;
      state.count = action.payload.count;
    },
    refetchAllProjects: (state, action: PayloadAction<boolean>) => {
      state.refetchProjects = action.payload;
    },
  },
});

export const { getAllProjectsData, refetchAllProjects } =
  allProjectsDetails.actions;
export default allProjectsDetails.reducer;
