import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControlProps,
} from "@mui/material";
import { Controller, FieldValues } from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type InputProps = FieldValues &
  FormControlProps & {
    customStyles?: CustomStyles<StylesClasses>;
    isMannual?: boolean;
    handleChange?: (e: any) => void;
    loading?: boolean;
    disable?: boolean;
  };

const Checkbox = ({
  name,
  control,
  label,
  loading,
  rules,
  customStyles,
  isMannual,
  disabled = false,
  handleChange,
  ...rest
}: InputProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <Controller
      render={({ field }) => (
        <FormControlLabel
          {...styles("wrapper")}
          control={
            <MuiCheckbox
              defaultChecked={field.value}
              onChange={(e) => {
                isMannual && handleChange(e);
                return field.onChange(e.target.checked);
              }}
              inputRef={field.ref}
              checked={field.value}
              disabled={disabled}
              // readOnly={true}
            />
          }
          label={label}
        />
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};
export default Checkbox;
