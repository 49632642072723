import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  button: {
    width: "5%",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.background.blue",

    margin: "1%",
    "&:hover": {
      backgroundColor: "custom.background.blue",
      color: "custom.text.white.100",
    },
  },
});

export default styles;
