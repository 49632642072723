import { Box, Button, Typography } from "@mui/material";
import { useQuery } from "react-query";
import StatsCard from "src/components/common/StatsCard";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import defaultStyles from "../styles";
import { getStyles } from "src/styles/theme";
import {
  getDashboardDetails,
  getTableDashboardDetails,
} from "src/utils/api/dashboard";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { DashBoardTable } from "src/components/common/DashBoardTable";
import {
  downloadSheet,
  exportEcoRestorationTableToXlsx,
  exportTableToXlsx,
  formatData,
  formatDataForBarChart,
} from "src/utils/helper";
import Fallback from "src/components/common/Fallback";
import { useEffect, useState } from "react";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import { Collapse } from "@mui/material";

import BarChartComponent from "src/components/common/BarComponent";
import RenderTable from "../../Home/RenderTable";
import { ecoRestorationDashboardtableColumns } from "src/constants/tableColumns";
import { AxiosResponse } from "axios";
import {
  EcoRestoartionDashBoardTable,
  ecoRestorationDashBoardTable,
} from "src/components/common/DashBoardTable/ecoRestoration";

const EcoRestorationDashboard = () => {
  const [isTabLoading, setIsTabLoading] = useState(true);

  const [states, setStates] = useState<any>([]);

  const [tableRows, setTableRows] = useState([]);

  const [filtersState, setFiltersState] = useState([]);

  const [queryParams, setQueryParams] = useState<{
    projectStateIds?: string[];
    type: string;
  }>({
    projectStateIds: undefined,
    type: "landLeased",
  });

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
    type: "",
  });

  const { data: dashboard, isLoading: dashboardLoading } = useQuery(
    ["dashboard", queryParams],
    () => getDashboardDetails(queryParams),
    {
      onSuccess: () => {
        setIsTabLoading(false);
      },
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const { data: tableDashboard, isLoading } = useQuery(
    ["table-landLeased", queryParams],
    () => getTableDashboardDetails(queryParams),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res?.data?.stats.map((items, idx) => ({
            id: idx,
            sno: idx + 1,
            state: items.state,
            totalAreaNodalVerificationPending:
              items.totalAreaNodalVerificationPending,
            totalNumberNodalVerificationPending:
              items.totalNumberNodalVerificationPending,
            totalAreaVerificationPending: items.totalAreaVerificationPending,
            totalNumberVerificationPending:
              items.totalNumberVerificationPending,
            totalAreaUnderQuery: items.totalAreaUnderQuery,
            totalNumberUnderQuery: items.totalNumberUnderQuery,
            totalAreaNodalUnderQuery: items.totalAreaNodalUnderQuery,
            totalNumberNodalUnderQuery: items.totalNumberNodalUnderQuery,
            totalAreaApproved: items.totalAreaApproved,
            totalNumberApproved: items.totalNumberApproved,
            totalAreaRejected: items.totalAreaRejected,
            totalNumberRejected: items.totalNumberRejected,
            totalAreaRegistered: items.totalAreaRegistered,
            totalNumberRegistered: items.totalNumberRegistered,
          }))
        );
        setIsTabLoading(false);
      },
      onError: () => {},
      refetchOnMount: true,
    }
  );

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const dashboardTableData: ecoRestorationDashBoardTable = {
    topHeader: [
      "",
      "All Registered",
      "SNO Verification",
      "Admin Verification",
      "SNO Query",
      "Under Query",
      "Approved",
      "Rejected",
    ],
    header: [
      "S.No",
      "State",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
    ],
    data: tableRows,
  };

  const formattedData = formatDataForBarChart(dashboard?.data?.stats);

  const routesConfig = {
    REGISTERED: "/registered-lands",
    VERIFICATION_PENDING: "/registered-lands?currentTab=VERIFICATION_PENDING",
    UNDER_QUERY: "/registered-lands?currentTab=UNDER_QUERY",
    APPROVED: "/registered-lands?currentTab=APPROVED",
    REJECTED: "/registered-lands?currentTab=REJECTED",
    NODAL_UNDER_QUERY: "/registered-lands?currentTab=NODAL_UNDER_QUERY",
  };

  const styles = getStyles(defaultStyles);
  useEffect(() => {
    setIsTabLoading(true);
  }, []);
  const [tableState, setTableState] = useState(false);

  useEffect(() => {
    setFiltersState(dashboard?.data?.state);
  }, [dashboard]);

  return (
    <>
      <Box {...styles("topHeader")}>
        <Box
          sx={{
            marginBottom: "70px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {filtersState && (
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                Selected States:-
                {filtersState?.map((filterState) => {
                  return (
                    <Box
                      sx={{
                        height: "46px",
                        padding: "11px",
                        width: "fit-content",
                        border: "1px solid grey",
                        borderRadius: "24px",
                        backgroundColor: "#eee",
                      }}
                    >
                      {filterState}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <MultiSelect
              options={states}
              onChange={(e: any) => {
                setFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    projectStateIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    projectStateIds: newState?.projectStateIds,
                  });

                  return newState;
                });
              }}
            />
          </Box>
        </Box>

        {!dashboardLoading ? (
          <Box sx={{ width: "75vw", marginBottom: "20px" }}>
            <Box sx={{ width: "80%", marginRight: "80px" }}>
              <BarChartComponent
                data={formattedData}
                label={"Status OF Eco-Restoration Blocks"}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box {...styles("statsCardWrapper", { marginTop: "20px" })}>
          {dashboard?.data?.stats?.map((stat, index) => (
            <StatsCard
              key={index}
              title={`${stat?.description?.toUpperCase()}`}
              value={`${stat?.number}`}
              area={stat?.totalArea}
              customStyles={{ valueCard: styles("statsCard").sx }}
              link={routesConfig[stat?.type]}
            />
          ))}
        </Box>
      </Box>

      {isLoading || isTabLoading ? (
        <Fallback />
      ) : (
        <Box {...styles("dashboardTableWrapper")}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" {...styles("heading")}>
              Status of Eco-Restoration Blocks Under GCP
            </Typography>
            <Button
              variant="outlined"
              onClick={() => setTableState(!tableState)}
            >
              {tableState ? (
                <>
                  Compress
                  <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
                </>
              ) : (
                <>
                  Expand
                  <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
                </>
              )}
            </Button>
          </Box>
          <Collapse in={tableState}>
            <Button
              variant="outlined"
              onClick={() =>
                exportEcoRestorationTableToXlsx(
                  dashboardTableData.data,
                  dashboardTableData.header,
                  dashboardTableData.topHeader
                )
              }
              sx={{ float: "right", marginBottom: "20px" }}
            >
              Export
            </Button>
            <EcoRestoartionDashBoardTable {...dashboardTableData} />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default EcoRestorationDashboard;
