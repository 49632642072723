import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { useAppSelector } from "src/redux/store";
import { CustomStyles, getStyles } from "src/styles/theme";
import {
  fundApplicationQuery,
  getProjectById,
  verifyFundApplication,
} from "src/utils/api/dashboard";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import defaultStyles, { StylesClasses } from "../styles";
import {
  dateAndTimeStamp,
  downloadSheet,
  formatToIndianCurrency,
} from "src/utils/helper";
import { Button } from "src/components/common/FormComponents/Buttons";
import { getFundApplicationById } from "src/utils/api/dashboard";
import { useForm } from "react-hook-form";
import { Input } from "src/components/common/FormComponents/Input";
import errorMessage from "src/constants/errorMessage";
import FileUpload from "src/components/common/FormComponents/FileUplaod";
import { path } from "src/constants/path";
import { useReactToPrint } from "react-to-print";

const FundApplicationDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [projectData, setProjectData] = useState<any>([]);

  const [searchParams] = useSearchParams();

  const componentRef = useRef();

  const FundApplicationId = searchParams.get("id");

  const user = useAppSelector((state) => state.root.user);

  const navigate = useNavigate();

  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery(
    ["userDetails", FundApplicationId],
    () => getFundApplicationById(FundApplicationId),
    {
      onSuccess: (res: AxiosResponse) => {
        setProjectData(res?.data);
      },
      onError: (req: AxiosError) => {},
      enabled: !!FundApplicationId,
      refetchOnMount: true,
    }
  );

  const projectId = userData?.data?.projectDetail?.id;

  const { data: projectDetails, isLoading: isProjectDetailLoading } = useQuery(
    ["projects", projectId],
    () => getProjectById(projectId),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
      enabled: !!projectId,
    }
  );

  const { mutate } = useMutation(
    "verifyFundApplication",
    ({
      FundApplicationId,
      data,
    }: {
      FundApplicationId: string;
      data: {
        status: string;
        remark: string;
        totalAmountReleased?: number;
        paymentApproveDocId?: string;
      };
    }) =>
      verifyFundApplication({
        FundApplicationId,
        data,
      }),
    {
      onSuccess: () => {
        navigate(path.fundTransfer);
      },
      onError: () => {},
    }
  );

  const { mutate: mutateQueryData } = useMutation(
    "query",
    ({ id, query }: { id: string; query: string }) =>
      fundApplicationQuery({
        id,
        query,
      }),
    {
      onSuccess: () => {
        refetch();
        setValue("query", "");
      },
      onError: () => {},
    }
  );

  const {
    control,
    watch,
    setValue,
    trigger,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      query: "",
      remark: "",
      totalAmountReleased: "",
      paymentApproveDocId: "",
    },
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const totalArea = Number(projectData?.fundDetails?.landOwnership?.totalArea);

  const docId = getValues("paymentApproveDocId");

  return (
    <Box>
      {!isLoading ? (
        <DashboardWrapper
          title="Fund Application Details"
          customStyles={{
            container: { position: "relative" },
            children: { paddingBottom: "80px" },
          }}
          buttonText="Print"
          onButtonClick={handlePrint}
          showBackBtn
        >
          <Box ref={componentRef} id="printable-content">
            <Box {...styles("projectName")}>
              Fund Application No : {projectData?.fundDetails?.id}
            </Box>
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Entity Details</Box>
              <ViewForm
                label="Entity Name"
                value={projectData?.fundDetails?.landOwnership?.user?.firstName}
              />
              <ViewForm
                label="Email"
                value={projectData?.fundDetails?.landOwnership?.user?.email}
              />
              <ViewForm
                label="Phone Number"
                value={`${projectData?.fundDetails?.landOwnership?.user?.phoneNumber}`}
              />
            </Box>
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Eco-Restoration Blocks Details</Box>
              <Box
                component="a"
                href={`${path.landOwnershipDetails}?${createSearchParams({
                  id: projectData?.fundDetails?.landOwnershipEpid,
                })}`}
                sx={{ ...defaultStyles.renderButtonText2 }}
              >
                <ViewForm
                  label="Cost Estimate ID"
                  value={projectData?.fundDetails?.landOwnershipEpid}
                />
              </Box>
              <ViewForm
                label="Land Registration No"
                value={
                  projectData?.fundDetails?.landOwnership?.projectDetail
                    ?.registration_no
                }
              />
              <ViewForm
                label="Eco-Restoration Amount"
                value={formatToIndianCurrency(
                  projectData?.fundDetails?.landOwnership?.demandNote
                )}
              />
              <ViewForm
                label="Net Planned Area(Hectares) "
                value={
                  projectData?.fundDetails?.landOwnership?.netPlantationArea
                }
              />
              <ViewForm
                label="Land Area Requested(Hectares) "
                value={totalArea.toFixed(2)}
              />
              <ViewForm
                label="State"
                value={
                  projectData?.fundDetails?.landOwnership?.projectDetail
                    ?.address?.stateDetail?.name
                }
              />
              <ViewForm
                label="District"
                value={
                  projectData?.fundDetails?.landOwnership?.projectDetail
                    ?.address?.districtDetail?.name
                }
              />
              <ViewForm
                label="Division"
                value={
                  projectData?.fundDetails?.landOwnership?.projectDetail
                    ?.landSpecification?.division
                }
              />
            </Box>
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>
                Estimated Fund Details for 1st Year
              </Box>
              <ViewForm
                label="Estimated Advance Work Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.estimatedAdvanceWorkCost
                )}`}
              />
              <ViewForm
                label="Estimated Raising/Creation Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.estimatedRaisingCost
                )}`}
              />
              <ViewForm
                label="Estimated Maintenance Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.estimatedMaintenanceCost
                )}`}
              />
              <ViewForm
                label="Estimated Monitoring Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.estimatedMonitoringCost
                )}`}
              />
              <ViewForm
                label="Estimated Contingency Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.estimatedContingencyCost
                )}`}
              />
              <ViewForm
                label="Total Estimated Cost for 1st Year"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.totalEstimatedCost
                )}`}
              />
            </Box>
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Requested Fund For 1st Year</Box>
              <ViewForm
                label="Advance Work Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.advanceWorkCost
                )}`}
              />
              <ViewForm
                label="Raising/Creation Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.raisingCost
                )}`}
              />
              <ViewForm
                label="Maintenance Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.maintenanceCost
                )}`}
              />
              <ViewForm
                label="Monitoring Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.monitoringCost
                )}`}
              />
              <ViewForm
                label="Contingency Cost"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.contingencyCost
                )}`}
              />
              <ViewForm
                label="Total Amount Requested"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.totalCost
                )}`}
              />
            </Box>

            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Additional Information</Box>
              <ViewForm
                label="Additional Information"
                value={projectData?.fundDetails?.additionalInfo}
              />
            </Box>
            {projectData?.paymentApplicationDocUrl && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>
                  Signed Fund Application From SNO
                </Box>
                <Box {...styles("documentGroup")}>
                  <ViewImages
                    fileUrl={projectData?.paymentApplicationDocUrl}
                    label={""}
                  />
                </Box>
              </Box>
            )}
            {projectData?.applicationApproveDocUrl && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Fund Released Order From Admin</Box>
                <Box {...styles("documentGroup")}>
                  <ViewImages
                    fileUrl={projectData?.applicationApproveDocUrl}
                    label={""}
                  />
                </Box>
              </Box>
            )}
            {projectData?.fundDetails?.totalAmountReleased && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Fund Details</Box>
                <ViewForm
                  label="1st Year Amount Released"
                  value={`${formatToIndianCurrency(
                    projectData?.fundDetails?.totalAmountReleased
                  )}`}
                />

                <ViewForm
                  label="Balance Amount"
                  value={`${formatToIndianCurrency(
                    projectData?.fundDetails?.amountLeft
                  )}`}
                />
              </Box>
            )}

            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>TimeLines</Box>
              {projectData?.fundDetails?.raisedDate && (
                <ViewForm
                  label="Fund Application Raised to Admin"
                  value={dateAndTimeStamp(projectData?.fundDetails?.raisedDate)}
                />
              )}

              {projectData?.fundDetails?.releasedDate && (
                <ViewForm
                  label="Fund Released"
                  value={dateAndTimeStamp(
                    projectData?.fundDetails?.releasedDate
                  )}
                />
              )}
            </Box>

            {projectData?.fundDetails?.queries?.length > 0 && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Fund Transfer Query</Box>
                {projectData?.fundDetails?.queries.map((item) => (
                  <Box sx={{ paddingTop: "20px" }}>
                    <ViewForm
                      label={item?.user?.firstName}
                      value={item?.query}
                      date={dateAndTimeStamp(item.created_at, "IST")}
                    />
                  </Box>
                ))}
              </Box>
            )}

            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Remarks</Box>
              <ViewForm
                label="SNO Remark"
                value={projectData?.fundDetails?.snoRemark}
              />
              {projectData?.fundDetails?.remark && (
                <ViewForm
                  label="Admin Remark"
                  value={projectData?.fundDetails?.remark}
                />
              )}
            </Box>

            {projectData?.paymentAcknowledgementDocUrl && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Transaction Receipt by SNO</Box>
                <Box {...styles("documentGroup")}>
                  <ViewImages
                    fileUrl={projectData?.paymentAcknowledgementDocUrl}
                    label={""}
                  />
                </Box>
              </Box>
            )}

            {projectData?.fundDetails?.status === "RAISED" && (
              <Box>
                <Box {...styles("input")}>
                  <Input
                    name="totalAmountReleased"
                    label="Total Amount Released BY Admin"
                    placeholder="Enter Total Amount Released BY Admin"
                    control={control}
                    rules={{ required: errorMessage.required }}
                    errors={errors}
                    height="52px"
                    type="number"
                    customStyles={{
                      input: {
                        width: { sm: "100%", lg: "60%" },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "60%" }}>
                  <FileUpload
                    // accept="image/jpeg, image/png, image/webp, image/jpg"
                    name="paymentApproveDocId"
                    label="Upload Fund Released Order"
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    maxLimit={1}
                    multiple={true}
                    customStyles={{
                      label: {
                        whiteSpace: "wrap",
                      },
                    }}
                    supportedFormatMessage="Upload Fund Released Order"
                    trigger={trigger}
                    required={true}
                    rules={{
                      validate: {
                        isLength: (value) => {
                          return value && value["length"] >= 1;
                        },
                      },
                    }}
                    dragDropHeading=""
                  />
                </Box>
                {user.data.role !== "Approver-view-only" && (
                  <Box sx={{ marginTop: "30px" }}>
                    <Input
                      name="query"
                      label="Query"
                      control={control}
                      errors={errors}
                      multiline
                      height="360px"
                      customStyles={{
                        input: {
                          height: "auto",
                          fontFamily: "Poppins",
                          width: { sm: "100%", lg: "60%" },
                        },
                      }}
                    />
                  </Box>
                )}
                <Box sx={{ marginTop: "30px" }}>
                  <Input
                    name="remarks"
                    label="Remarks"
                    control={control}
                    errors={errors}
                    multiline
                    height="360px"
                    customStyles={{
                      input: {
                        height: "auto",
                        fontFamily: "Poppins",
                        width: { sm: "100%", lg: "60%" },
                      },
                    }}
                  />
                </Box>
                {user.data.role !== "Approver-view-only" && (
                  <Box {...styles("btnWrapper")}>
                    <Button
                      text="Reject"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        mutate({
                          FundApplicationId,
                          data: {
                            status: "REJECTED",
                            remark: watch("remarks"),
                          },
                        });
                      }}
                      customStyles={{
                        root: {
                          fontSize: "18px",
                          color: "error.main",
                          borderColor: "error.main",
                        },
                      }}
                    />
                    <Button
                      text="Approve"
                      type="submit"
                      disabled={!isValid}
                      onClick={() => {
                        mutate({
                          FundApplicationId,
                          data: {
                            status: "APPROVED",
                            remark: watch("remarks"),
                            totalAmountReleased: Number(
                              watch("totalAmountReleased")
                            ),
                            paymentApproveDocId: docId[0]?.key,
                          },
                        });
                      }}
                      customStyles={{
                        root: {
                          fontSize: "16px",
                        },
                      }}
                    />
                  </Box>
                )}
                {user.data.role !== "Approver-view-only" && (
                  <Button
                    text="Raise Query"
                    onClick={() => {
                      mutateQueryData({
                        id: FundApplicationId,
                        query: watch("query"),
                      });
                    }}
                    disabled={!watch("query")}
                    customStyles={{
                      root: {
                        fontSize: "16px",
                        position: "absolute",
                        bottom: "20px",
                      },
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </DashboardWrapper>
      ) : (
        <Box {...styles("loading")}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default FundApplicationDetails;
