import { Box, Button } from "@mui/material";
import { logout } from "src/redux/slices/userDetails";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import defaultStyles, { StylesClasses } from "./styles";
import { CustomStyles, getStyles } from "src/styles/theme";

type AdminProps = {
  customStyles?: CustomStyles<StylesClasses>;
};

const Admin = ({ customStyles }: AdminProps) => {
  const styles = getStyles(defaultStyles, customStyles);
  const user = useAppSelector((state) => state.root.user);
  const dispatch = useAppDispatch();
  return (
    <Box>
      <h1>Admin</h1>
      <Box className="container">
        <Box className="user">
          {user.isLoggedIn ? (
            <Box>Current user is {user.name}</Box>
          ) : (
            <Box>Not logged in</Box>
          )}
        </Box>
        <Button
          {...styles("button")}
          onClick={(e) => {
            dispatch(logout());
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Admin;
