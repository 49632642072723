import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  renderButtonText: {
    display: "block",
    textAlign: "left",
    fontSize: "17px",
  },
  deleteUser: {
    display: "block",
    textAlign: "left",
    fontSize: "14px",
    color: "red",
  },
  searchBarWrapper: {
    marginBottom: "20px",
    height: "50px",
  },
  statsCardWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
    padding: "15px",
  },
  statsCard: {
    width: "337px",
    height: "140px",
  },
});

export default styles;
