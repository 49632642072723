import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    marginLeft: "-30px",
  },

  name: {
    color: "custom.text.profileMenuName",
  },

  Reject: {
    color: "error.main",
    fontSize: "14px",
    fontWeight: 500,
  },

  menuOption: {
    "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
      border: "0.5px solid",
      borderColor: "custom.border.secondary",
      borderRadius: "8px",
    },
  },
  menuItem: {},
  Approve: {
    color: "custom.text.label",
    fontSize: "14px",
    fontWeight: 500,
  },
});

export default styles;
