import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { memo, useEffect, useState } from "react";

type MapComponentProps = {
  kmlUrl: any;
};

const MapComponent = ({ kmlUrl = "" }: MapComponentProps) => {
  const [map, setMap] = useState(null);

  const onLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (map) {
      // const kmlUrl =
      //   "https://greencreditbucket.s3.ap-south-1.amazonaws.com/GCDOC1707891272334?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVCOYGCWF3XK2CG6I%2F20240214%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240214T065457Z&X-Amz-Expires=86340&X-Amz-Signature=fe53e6df9b6375ba4c73be1d37af4cb8a1df1918fc2ca188f785e145573c014f&X-Amz-SignedHeaders=host";

      const kmlLayer = new window.google.maps.KmlLayer({
        url: kmlUrl,
        map: map,
      });

      return () => {
        kmlLayer.setMap(null);
      };
    }
  }, [kmlUrl, map]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyAyOMZXsDXidzVhWywof3jRCzQ_4IlCzbY">
      <GoogleMap
        mapContainerStyle={{ height: "400px", width: "100%" }}
        center={{ lat: 0, lng: 0 }}
        zoom={2}
        onLoad={onLoad}
      ></GoogleMap>
    </LoadScript>
  );
};

const propsAreEqual = (
  prevProps: MapComponentProps,
  nextProps: MapComponentProps
) => {
  // Add custom logic to compare prevProps and nextProps
  // Return true if props are equal, otherwise false

  return prevProps.kmlUrl === nextProps.kmlUrl;
};

export default memo(MapComponent, propsAreEqual);
