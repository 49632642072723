import { Box, FormControlProps, InputBaseProps } from "@mui/material";
import {
  FieldErrors,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";
import { Input, Select } from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type PhoneNumberProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    errors?: FieldErrors;
    customStyles?: CustomStyles<StylesClasses>;
    readOnly?: boolean;
    rules?: boolean;
    values?: any;
  };

export const countryCode = [
  {
    label: "+91",
    value: "+91",
  },
];

const PhoneNumber = <T extends FieldValues>({
  control,
  errors,
  name,
  readOnly = false,
  rules = true,
  values,
}: PhoneNumberProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  return (
    <Box {...styles("wrapper")}>
      <Select
        name={`${name}.countryCode` as Path<T>}
        control={control}
        errors={errors}
        // value={values?.countryCode}
        disabled={true}
        customStyle={{
          wrapper: {
            ...defaultStyles.customWrapper,
          },
        }}
        options={countryCode}
      />
      <Input
        name={`${name}.number` as Path<T>}
        label="Phone Number"
        type="number"
        placeholder="Enter your phone number"
        errors={errors}
        height="52px"
        readOnly={readOnly}
        // value={values?.contactNo}
        control={control}
        customStyles={{
          wrapper: {
            ...defaultStyles.phoneCustom,
          },
          errorMsg: {
            ...defaultStyles.errorCustom,
          },
          input: {
            paddingLeft: "86px",
          },
        }}
        rules={
          rules && {
            required: errorMessage.required,
            minLength: {
              value: 10,
              message: errorMessage.phoneLength,
            },

            maxLength: {
              value: 10,
              message: errorMessage.phoneLength,
            },
          }
        }
      />
    </Box>
  );
};

export default PhoneNumber;
