export const tableColumns = [
  {
    field: "sno",
    headerName: "S.No",
    width: 90,
    sortable: false,
    type: ["registeredLands", "registeredEntity"],
    tab: [],
  },
  {
    field: "id",
    headerName: "Id",
    width: 200,
    sortable: false,
    renderCell: "VIEW_BUTTON",
    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "id",
    headerName: "ID",
    width: 200,
    sortable: false,
    hide: true,
    type: ["registeredLands"],
    tab: [],
  },
  {
    field: "generateDemandNote",
    headerName: "Status",
    width: 250,
    sortable: false,
    renderCell: "DEMAND_DRAFT",
    type: ["registeredEntity"],
    tab: [
      "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
      "NODAL_UNDER_QUERY",
      "PAYMENT_PENDING",
      "VERIFICATION_PENDING",
      "APPROVED",
    ],
  },
  {
    field: "viewDemandNote",
    headerName: "View Payment Details",
    width: 200,
    sortable: false,
    renderCell: "VIEW_DEMAND",
    type: ["registeredEntity"],
    tab: [
      "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
      "VERIFY_DEMAND_NOTE",
      "UNDER_QUERY",
      "PAYMENT_PENDING",
      "APPROVED",
      "REJECTED",
      "NODAL_UNDER_QUERY",
    ],
  },
  {
    field: "registrationNo",
    headerName: "Reg.No",
    width: 100,
    sortable: false,
    renderCell: "BUTTON",
    type: ["registeredLands"],
    tab: [],
  },
  {
    field: "registrationNo",
    headerName: "Reg.No",
    width: 100,
    sortable: false,
    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "entityName",
    headerName: "Entity Name",
    width: 200,
    sortable: true,

    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "state",
    headerName: "State",
    width: 200,
    sortable: true,
    type: ["registeredEntity", "registeredLands"],
    tab: [],
  },
  {
    field: "district",
    headerName: "District",
    width: 200,
    sortable: true,
    type: ["registeredEntity", "registeredLands"],
    tab: [],
  },
  {
    field: "division",
    headerName: "Forest Division",
    width: 200,
    sortable: true,
    type: ["registeredLands"],
    tab: [],
  },
  {
    field: "allocatedivision",
    headerName: "Allocate Forest Division",
    width: 200,
    sortable: false,
    type: ["registeredLands"],
    tab: [],
  },
  {
    field: "division",
    headerName: "Division",
    width: 200,
    sortable: true,
    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "range",
    headerName: "Range",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "treeCanopy",
    headerName: "Tree Canopy Density",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "landArea",
    headerName: "Total Area (Ha)",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "areaUnit",
    headerName: "Requested Area (Ha)",
    width: 220,
    sortable: false,
    type: ["registeredEntity"],
    tab: [],
  },
  {
    field: "netPlantationArea",
    headerName: "Final Area (Ha)",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: ["GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE"],
  },
  {
    field: "areaUnit",
    headerName: "Area (Ha)",
    width: 200,
    sortable: false,
    type: ["registeredLands"],
    tab: [],
  },

  {
    field: "demandNote",
    headerName: "Eco-Restoration Amount(₹)",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: ["GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE"],
  },
  {
    field: "estimateAmount",
    headerName: "Demand Note Amount(₹)",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: ["GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE"],
  },
  {
    field: "totalAmountRecived",
    headerName: "Total Amount Received(₹)",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: [
      "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
      "VERIFY_DEMAND_NOTE",
      "UNDER_QUERY",
      "PAYMENT_PENDING",
      "REJECTED",
    ],
  },
  {
    field: "ecoRestorationCostRecived",
    headerName: "Eco-Restoration Cost Received(₹)",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: [
      "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
      "VERIFY_DEMAND_NOTE",
      "UNDER_QUERY",
      "PAYMENT_PENDING",
      "REJECTED",
    ],
  },

  {
    field: "adminChargesRecived",
    headerName: "Admin Charge Received(₹)",
    width: 200,
    sortable: false,
    type: ["registeredEntity"],
    tab: [
      "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
      "VERIFY_DEMAND_NOTE",
      "UNDER_QUERY",
      "PAYMENT_PENDING",
      "REJECTED",
    ],
  },

  {
    field: "created_at",
    headerName: "CREATED AT",
    width: 250,
    sortable: false,
    type: ["registeredLands", "registeredEntity"],
    tab: [],
  },
  {
    field: "updated_at",
    headerName: "UPDATED AT",
    width: 250,
    sortable: true,
    type: ["registeredLands", "registeredEntity"],
    tab: [],
  },

  {
    field: "status",
    headerName: "Status",
    width: 250,
    sortable: false,
    type: ["registeredLands"],
    tab: [],
  },

  {
    field: "remark",
    headerName: "Remark",
    width: 200,
    sortable: false,
    type: ["registeredEntity", "registeredLands"],
    tab: [],
  },
];

export const entityDashboardtableColumns: any = [
  {
    field: "id",
    headerName: "id",
    width: 75,
    sortable: false,
    hide: true,
  },
  {
    field: "sno",
    headerName: "S No.",
    width: 75,
    sortable: false,
  },

  {
    field: "entityName",
    headerName: "Entity Name",
    width: 200,
    sortable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 200,
    sortable: false,
  },
  {
    field: "totalBlocks",
    headerName: "Total Numbers",
    width: 150,
    sortable: false,
  },
  {
    field: "totalBlocksArea",
    headerName: "Total Area",
    width: 150,
    sortable: false,
  },
  {
    field: "numberDemandNotePending",
    headerName: "DemandNote Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "areaDemandNotePending",
    headerName: "DemandNote Pending Area",
    width: 150,
    sortable: false,
  },
  {
    field: "numberPaymentPending",
    headerName: "Payment Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "areaPaymentPending",
    headerName: "Payment Pending Area",
    width: 150,
    sortable: false,
  },
  {
    field: "numberPaymentDone",
    headerName: "Payment Done",
    width: 150,
    sortable: false,
  },
  {
    field: "areaPaymentDone",
    headerName: "Payment Done Area",
    width: 150,
    sortable: false,
  },
];

export const ecoRestorationDashboardtableColumns: any = [
  {
    field: "id",
    headerName: "id",
    width: 75,
    sortable: false,
    hide: true,
  },
  {
    field: "sno",
    headerName: "S No.",
    width: 75,
    sortable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 200,
    sortable: false,
  },
  {
    field: "totalNumberRegistered",
    headerName: "Total Numbers",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaRegistered",
    headerName: "Total Area",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberNodalVerificationPending",
    headerName: "Total Number SNO Verification Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaNodalVerificationPending",
    headerName: "Total Area SNO Verification Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberVerificationPending",
    headerName: "Total Number Admin Verification Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaVerificationPending",
    headerName: "Total Area Admin Verification Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberUnderQuery",
    headerName: "Total Number Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaUnderQuery",
    headerName: "Total Area Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberNodalUnderQuery",
    headerName: "Total Number SNO Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaNodalUnderQuery",
    headerName: "Total Area Nodal Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberApproved",
    headerName: "Total Number Approved Blocks",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaApproved",
    headerName: "Total Area Approved",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberRejected",
    headerName: "Total Number Rejected Blocks",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaRejected",
    headerName: "Total Area Rejected",
    width: 150,
    sortable: false,
  },
];

export const costEstimateDashboardtableColumns: any = [
  {
    field: "id",
    headerName: "id",
    width: 75,
    sortable: false,
    hide: true,
  },
  {
    field: "sno",
    headerName: "S No.",
    width: 75,
    sortable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 200,
    sortable: false,
  },
  {
    field: "totalNumberRegistered",
    headerName: "Total Selected",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaRegistered",
    headerName: "Total Selected Area",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberGenerateDemandNote",
    headerName: "Total Number Eco-Restoration Cost Estimate Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaGenerateDemandNote",
    headerName: "Total Area Eco-Restoration Cost Estimate Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberNodalUnderQuery",
    headerName: "Total Number SNO Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaNodalUnderQuery",
    headerName: "Total Area SNO Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberUnderQuery",
    headerName: "Total Number Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaUnderQuery",
    headerName: "Total Area Under Query",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberPaymentPending",
    headerName: "Total Number Payment Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaPaymentPending",
    headerName: "Total Area Payment Pending",
    width: 150,
    sortable: false,
  },
  {
    field: "totalNumberPaymentDone",
    headerName: "Total Number Payment Done",
    width: 150,
    sortable: false,
  },
  {
    field: "totalAreaPaymentDone",
    headerName: "Total Area Payment Done",
    width: 150,
    sortable: false,
  },
];
