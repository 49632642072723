import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { DashboardWrapper } from "src/components/common/DashboardWrapper";
import {
  FileUpload,
  Input,
  PhoneNumber,
  Select,
} from "src/components/common/FormComponents";
import { devTypes, DOCUMENT_ENUM } from "src/constants/common";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { getMyProfileData } from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "./styles";

const MyProfile = () => {
  const userId = useAppSelector((state) => state.root.user.data.userId);
  const userType = useAppSelector((state) => state.root.user.data.userType);

  const { data: myProfileData } = useQuery(
    ["myProfile", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: (res: AxiosResponse) => {
        // console.log("ress", res);
      },
      onError: (err: AxiosError) => {
        // console.log("err", err.response);
      },
      enabled: !!userId,
    }
  );

  useEffect(() => {
    if (!!myProfileData?.data) {
      reset({
        firstName: "",
        email: "",
        phoneNumber: {
          countryCode: userData.countryCode,
          number: userData.phoneNumber,
        },
        entityType: userData?.organizationType,
        aadhaarCardBack: [
          {
            key: userData?.userDocuments[0]?.frontUrl,
            file: userData?.userDocuments[0]?.frontUrl,
          },
        ],
        aadhaarCardFront: [
          {
            key: userData?.userDocuments[0]?.backUrl,
            file: userData?.userDocuments[0]?.backUrl,
          },
        ],
        registration: [
          {
            key: userData?.userDocuments[0]?.frontUrl,
            file: userData?.userDocuments[0]?.frontUrl,
          },
        ],
        documentTypes: [
          {
            key: userData?.userDocuments[0]?.frontUrl,
            file: userData?.userDocuments[0]?.frontUrl,
          },
        ],
        documentType: userData?.userDocuments[0]?.type,
        userProfileType: userData?.role,
        devType: userData?.userType,
        aadhaarNumber: userData?.userDocuments[0]?.serialNo,
        currentAddress: {
          addressLine1: "",
          city: userData?.currentAddress?.city,
          district: "",
          state: userData?.currentAddress?.state,
        },
        permanentAddress: {
          addressLine1: userData?.permanentAddress?.addressLine1,
          city: userData?.permanentAddress?.city,
          district: userData?.permanentAddress?.district,
          state: userData?.permanentAddress?.state,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfileData]);

  const userData = myProfileData?.data;

  const getDocumentValue = useCallback(
    (config?: {
      fileName?: "userDocuments";
      type?: "backUrl" | "frontUrl";
      keyName?: "serialNo";
    }) => {
      const { fileName, type, keyName } = {
        fileName: "userDocuments",
        type: "frontUrl",
        ...(config ?? {}),
      };

      return userData
        ? [
            {
              key: userData[fileName]?.[0]?.[keyName ?? type],
              file: userData[fileName]?.[0]?.[type],
            },
          ]
        : [];
    },
    [userData]
  );

  const {
    control,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    watch,
    reset,
  } = useForm<any>({
    mode: "onTouched",

    defaultValues: {
      phoneNumber: {
        countryCode: userData?.countryCode,
        number: "",
      },
      aadhaarNumber: userData?.userDocuments[0]?.serialNo,
      documentType: userData?.userDocuments[0]?.type,
      firstName: "",
      email: "",
      role: "greenCreditProjectDeveloper",
      devType: userData?.userType,
      currentAddress: {
        addressLine1: "",
        city: userData?.currentAddress?.city,
        district: "",
        state: userData?.currentAddress?.state,
      },
      entityType: userData?.organizationType,
      permanentAddress: {
        addressLine1: userData?.permanentAddress?.addressLine1,
        city: userData?.permanentAddress?.city,
        district: userData?.permanentAddress?.district,
        state: userData?.permanentAddress?.state,
      },
    },
  });

  const { errors } = formState;
  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <DashboardWrapper title="My Projects" showBackBtn={true} backPath={"/"}>
      <Box {...styles("wrapper")}>
        <Box {...styles("userDetails")}>
          <Box {...styles("heading")}>User Type Details</Box>
          <Box {...styles("container")}>
            <Box {...styles("input")}>
              <Select
                name="userProfileType"
                label="User Type"
                control={control}
                errors={errors}
                height="52px"
                disabled
                options={[
                  {
                    label: userData?.role,
                    value: userData?.role,
                  },
                ]}
                // options={helper}
                customStyle={{ wrapper: defaultStyles.selectWrapper }}
                /*  rules={{
                  required: errorMessage.required,
                }} */
              />
            </Box>
          </Box>
        </Box>

        <Box {...styles("personalDetails")}>
          <Box {...styles("heading")}>personal Details</Box>
          <Box {...styles("container")}>
            {userType === devTypes.ORGANIZATION && (
              <Box {...styles("input")}>
                <Select
                  name="entityType"
                  label="Entity Type"
                  control={control}
                  errors={errors}
                  height="52px"
                  disabled
                  customStyle={{ wrapper: defaultStyles.selectWrapper }}
                  /* rules={{
                    required: errorMessage.required,
                  }} */
                  options={[
                    {
                      label: userData?.organizationType,
                      value: userData?.organizationType,
                    },
                  ]}
                />
              </Box>
            )}
            <Box
              {...(styles("input"),
              {
                flexBasis:
                  userType === devTypes.INDIVIDUAL
                    ? {
                        xs: "100%",
                        sm: "75%",
                        lg: "83%",
                      }
                    : {
                        xs: "100%",
                        sm: "75%",
                        lg: "40%",
                      },
                marginBottom: userType === devTypes.INDIVIDUAL ? "20px" : "0px",
              })}
            >
              <Input
                name="firstName"
                label={
                  userType === devTypes.ORGANIZATION
                    ? "Entity Name"
                    : "Full Name"
                }
                placeholder="Enter full name"
                control={control}
                value={
                  userType === devTypes.ORGANIZATION
                    ? userData?.organizationName
                    : userData?.firstName
                }
                // rules={{ required: errorMessage.required }}
                errors={errors}
                height="52px"
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
                readOnly={true}
              />
            </Box>

            <Box {...styles(["container", "fileBox"])}>
              {userType === devTypes.ORGANIZATION && (
                <Box
                  {...(styles(["input", "fileUpload"]),
                  {
                    flexBasis: {
                      xs: "100%",
                      sm: "75%",
                      lg: "85%",
                    },
                  })}
                >
                  <FileUpload
                    name="registration"
                    label="Certificate of registration or Undertaking of Association on stamp paper (media file)"
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    defaultFiles={getDocumentValue()}
                    // value={}
                    disabled={true}
                    maxLimit={5}
                    trigger={trigger}
                    required={false}
                    readOnly={true}
                    customStyles={{
                      wrapper: defaultStyles.selectWrapper,
                      label: {
                        whiteSpace: "wrap",
                      },
                    }}
                    /*  rules={{
                      validate: {
                        isLength: (value) => {
                          return value && value["length"] >= 1;
                        },
                      },
                    }} */
                    dragDropHeading=""
                  />
                </Box>
              )}
            </Box>

            {userType === devTypes.ORGANIZATION && (
              <>
                <Box {...styles("input")}>
                  <Input
                    name="authName"
                    label="Authorised Representative Name"
                    placeholder="Authorised Representative Name"
                    control={control}
                    value={userData?.firstName}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    // rules={{ required: errorMessage.required }}
                    errors={errors}
                    height="52px"
                    readOnly={true}
                  />
                </Box>

                <Box {...styles("input")}>
                  <Input
                    name="authDesig"
                    label="Authorised Representative Designation"
                    placeholder="Authorised Representative Designation"
                    control={control}
                    value={userData?.representativeDesignation}
                    // rules={{ required: errorMessage.required }}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    errors={errors}
                    height="52px"
                    readOnly={true}
                  />
                </Box>
              </>
            )}

            <Box {...styles(["input", "withHelperText"])}>
              <Input
                name="email"
                label="Email"
                placeholder="Enter your email"
                value={userData?.email}
                control={control}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
                readOnly={true}
                /* rules={{
                  required: errorMessage.required,
                  pattern: {
                    value: regex.email,
                    message: errorMessage.invalidEmail,
                  },
                }} */
                errors={errors}
                height="52px"
              />
              {userType === devTypes.ORGANIZATION && (
                <Box {...styles("fieldHelperText")}>
                  (Company’s official or representative’s email)
                </Box>
              )}
            </Box>

            <Box {...styles(["input", "withHelperText"])}>
              <PhoneNumber
                control={control}
                errors={errors}
                name="phoneNumber"
                customStyles={{ phoneCustom: defaultStyles.selectWrapper }}
                readOnly={true}
                /* value={{
                  countryCode: userData.countryCode,
                  contactNo: userData.phoneNumber,
                }} */
                // rules={false}
              />

              {userType === devTypes.ORGANIZATION && (
                <Box {...styles("fieldHelperText")}>
                  (Company’s official or representative’s phone number)
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box {...styles("documentUpload")}>
          <Box {...styles("heading")}>
            {userType === devTypes.ORGANIZATION
              ? "Document Upload"
              : "Aadhaar Verification"}
          </Box>
          <Box {...styles("container")}>
            {userType === devTypes.ORGANIZATION && (
              <Box
                {...(styles("input"),
                {
                  flexBasis: {
                    xs: "100%",
                    sm: "75%",
                    lg: "85%",
                  },
                })}
              >
                <Select
                  name="documentType"
                  label="Document Type"
                  control={control}
                  errors={errors}
                  options={[
                    {
                      label: userData?.userDocuments[0].type,
                      value: userData?.userDocuments[0].type,
                    },
                  ]}
                  disabled
                  customStyle={{ wrapper: defaultStyles.selectWrapper }}
                  // rules={{ required: errorMessage.required }}
                  height="52px"
                />
              </Box>
            )}

            {userType === devTypes.ORGANIZATION && (
              <Box
                {...(styles(["input", "fileUpload"]),
                {
                  flexBasis: {
                    xs: "100%",
                    sm: "75%",
                    lg: "85%",
                  },
                  marginTop: "25px",
                })}
              >
                <FileUpload
                  name="documentTypes"
                  label={
                    watch("documentType") === DOCUMENT_ENUM.PAN
                      ? "Upload Pan Card Image"
                      : "Upload CIN/GSTIN(UIN) Image"
                  }
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  defaultFiles={getDocumentValue()}
                  maxLimit={5}
                  required={false}
                  trigger={trigger}
                  readOnly={true}
                  disabled={true}
                  customStyles={{
                    wrapper: defaultStyles.selectWrapper,
                    label: {
                      whiteSpace: "wrap",
                    },
                  }}
                  dragDropHeading=""
                />
              </Box>
            )}

            {userType === devTypes.INDIVIDUAL && (
              <>
                <Box
                  {...(styles("input"),
                  {
                    flexBasis: {
                      xs: "100%",
                      sm: "75%",
                      lg: "85%",
                    },
                  })}
                >
                  <Input
                    name="aadhaarNumber"
                    label="Aadhaar Number"
                    placeholder="Enter your aadhaar number"
                    type="text" //-------------------- Just for check-------------
                    readOnly={true}
                    value={userData?.userDocuments[0]?.serialNo}
                    control={control}
                    /* rules={{
                      required: errorMessage.required,
                      pattern: {
                        value: regex.aadhaarRegex,
                        message: errorMessage.aadharPattern,
                      },
                    }} */
                    height="52px"
                    errors={errors}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box {...styles("registeredAddress")}>
          <Box {...styles("heading")}>Registered address</Box>
          <Box {...styles("container")}>
            <Box {...styles("input")}>
              <Input
                name="currentAddress.addressLine1"
                label="Street Address"
                placeholder="Enter street address"
                control={control}
                // rules={{ required: errorMessage.required }}
                errors={errors}
                value={userData?.currentAddress?.addressLine1}
                height="52px"
                readOnly={true}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
              />
            </Box>
            <Box {...styles("input")}>
              <Select
                name="currentAddress.city"
                label="City"
                control={control}
                errors={errors}
                height="52px"
                disabled
                customStyle={{ wrapper: defaultStyles.selectWrapper }}
                /* rules={{
                  required: errorMessage.required,
                }} */
                options={[
                  {
                    label: userData?.currentAddress?.city,
                    value: userData?.currentAddress?.city,
                  },
                ]}
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                name="currentAddress.district"
                label="District"
                placeholder="Enter district"
                control={control}
                // rules={{ required: errorMessage.required }}
                errors={errors}
                value={userData?.currentAddress?.district}
                height="52px"
                readOnly={true}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
              />
            </Box>
            <Box {...styles("input")}>
              <Select
                name="currentAddress.state"
                label="State"
                control={control}
                errors={errors}
                disabled
                height="52px"
                customStyle={{ wrapper: defaultStyles.selectWrapper }}
                /* rules={{
                  required: errorMessage.required,
                }} */
                options={[
                  {
                    label: userData?.currentAddress?.state,
                    value: userData?.currentAddress?.state,
                  },
                ]}
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                name={"currentAddress.zipCode"}
                label="Pin Code"
                type="number"
                placeholder="Enter your pin code"
                control={control}
                height="52px"
                readOnly={true}
                value={userData?.currentAddress?.zipCode}
                /* rules={{
                  required: errorMessage.required,
                }} */
                errors={errors}
              />
            </Box>
          </Box>
        </Box>

        <Box {...styles("permanentAddress")}>
          <Box {...styles("heading")}>permanent address</Box>
          <Box {...styles("container")}>
            <Box {...styles("input")}>
              <Input
                name="permanentAddress.addressLine1"
                label="Street Address"
                placeholder="Enter street address"
                control={control}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
                // rules={{ required: errorMessage.required }}
                value={userData?.permanentAddress?.addressLine1}
                errors={errors}
                readOnly={true}
                height="52px"
              />
            </Box>
            <Box {...styles("input")}>
              <Select
                name="permanentAddress.city"
                label="City"
                control={control}
                errors={errors}
                height="52px"
                disabled
                customStyle={{ wrapper: defaultStyles.selectWrapper }}
                /* rules={{
                  required: errorMessage.required,
                }} */
                options={[
                  {
                    label: userData?.permanentAddress?.city,
                    value: userData?.permanentAddress?.city,
                  },
                ]}
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                name="permanentAddress.district"
                label="District"
                placeholder="Enter district"
                control={control}
                // rules={{ required: errorMessage.required }}
                errors={errors}
                readOnly={true}
                value={userData?.permanentAddress?.district}
                height="52px"
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
              />
            </Box>
            <Box {...styles("input")}>
              <Select
                name="permanentAddress.state"
                label="State"
                control={control}
                errors={errors}
                height="52px"
                customStyle={{ wrapper: defaultStyles.selectWrapper }}
                /* rules={{
                  required: errorMessage.required,
                }} */
                disabled
                options={[
                  {
                    label: userData?.permanentAddress?.state,
                    value: userData?.permanentAddress?.state,
                  },
                ]}
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                name={"permanentAddress.zipCode"}
                label="Pin Code"
                type="number"
                placeholder="Enter your pin code"
                height="52px"
                control={control}
                readOnly={true}
                /* rules={{
                  required: errorMessage.required,
                }} */
                value={userData?.permanentAddress?.zipCode}
                errors={errors}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardWrapper>
  );
};

export default MyProfile;
