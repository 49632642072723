import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import PopUp from "src/components/common/PopUp";
import { CustomStyles, getStyles } from "src/styles/theme";
import { Button } from "../../FormComponents";
import defaultStyles, { StylesClasses } from "./styles";

type SuccessModalProps = {
  heading?: string;
  subheading?: string;
  description?: string;
  btnText2?: string;
  open: boolean;
  btnText?: string;
  type?: string;
  onClick?: () => void;
  handleModalClose?: () => void;
  children?: React.ReactNode;
  customStyle?: CustomStyles<StylesClasses>;
  closeOnBackDrop?: boolean;
};

const DeleteModal = ({
  heading = "Congratulations!",
  subheading = "",
  open,
  handleModalClose,
  onClick,
  type = "success",
  btnText = "",
  btnText2 = "",
  children,
  customStyle,
  closeOnBackDrop = true,
}: SuccessModalProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyle);
  return (
    <PopUp
      open={open}
      handleClose={() => handleModalClose()}
      closeOnBackDrop={closeOnBackDrop}
      actionLeft={{
        hidden: true,
      }}
      actionRight={{
        hidden: true,
      }}
      heading={""}
    >
      <Box {...styles("wrapper")}>
        <Box {...styles("cancelBox")} onClick={handleModalClose}>
          <CloseIcon {...styles("closeIcon")} />
        </Box>
        <Box {...styles("iconWrapper")}>
          <Box
            component={"img"}
            src={
              type === ""
                ? "/assets/images/check-mark 1.png"
                : "/assets/images/unsuccessfulIcon.png"
            }
            {...styles("img")}
          ></Box>
        </Box>
        <Box {...styles("title")}>{heading}</Box>
        <Box>
          <Box {...styles("description")}>{`UserName: ${subheading}`}</Box>
          {children}
        </Box>
        <Box {...styles("btn")}>
          {btnText && (
            <Button
              text={btnText}
              type={type === "success" ? "button" : "submit"}
              customStyles={{ contained: defaultStyles.btnDimensions }}
              onClick={onClick}
            />
          )}
          {btnText2 && (
            <Button
              text={btnText2}
              type={type === "success" ? "button" : "submit"}
              customStyles={{ contained: defaultStyles.btnDimensions }}
              onClick={handleModalClose}
            />
          )}
        </Box>
      </Box>
    </PopUp>
  );
};

export default DeleteModal;
