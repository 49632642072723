import React from "react";

import { Box } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import TitleBox from "../TitleBox";
import defaultStyles, { StylesClasses } from "./styles";
import { To } from "react-router-dom";

type ContainerProps = {
  children?: React.ReactNode;
  customStyles?: CustomStyles<StylesClasses>;
  title: string;
  subTitle?: string;
  buttonIcon?: string;
  buttonText?: string;
  showBtn?: boolean;
  showBackBtn?: boolean;
  onButtonClick?: () => void;
  backPath?: To;
};

export const DashboardWrapper = ({
  children,
  title,
  subTitle,
  buttonIcon,
  buttonText,
  customStyles,
  showBtn = true,
  showBackBtn = false,
  onButtonClick,
  backPath,
}: ContainerProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("container")}>
      <TitleBox
        title={title}
        subTitle={subTitle}
        buttonIcon={buttonIcon}
        buttonText={buttonText}
        onClick={onButtonClick}
        showBackBtn={showBackBtn}
        backPath={backPath}
        showBtn
      />
      <Box {...styles("children")}>{children}</Box>
    </Box>
  );
};

export default DashboardWrapper;
