import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const MultiSelect = ({
  options,
  onChange,
  label,
}: {
  options: {
    description: null;
    id: string;
    name: string;
  }[];
  onChange: (e: any) => void;
  label?: string;
}) => {
  const [selectedNames, setSelectedNames] = useState<string[]>([]);

  const handleSelection = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedNames.includes(selectedValue)) {
      setSelectedNames([
        ...selectedNames.filter((name) => name !== selectedValue),
      ]);
    } else {
      setSelectedNames([...selectedValue]);
    }
    onChange(selectedValue);
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      {!!!selectedNames.length && (
        <InputLabel
          style={{
            position: "absolute",
            top: "10px",
            left: "20px",
            color: "gray",
          }}
          id="demo-multiple-checkbox-label"
        >
          {label ? label : "Filter By States"}
        </InputLabel>
      )}
      <Select
        multiple
        value={selectedNames}
        onChange={(e) => handleSelection(e)}
        MenuProps={MenuProps}
      >
        {options?.map((option) => (
          <MenuItem key={option.name} value={option.id}>
            <Checkbox checked={selectedNames.indexOf(option.id) > -1} />
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
