import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CustomTooltip from "./tooltip";

const BarChartComponent = ({ data, label }) => {
  const renderCustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    const value = payload.value;
    const words = value.split(" ");
    const lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if (currentLine.length + word.length > 10) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += (currentLine ? " " : "") + word;
      }
    });

    lines.push(currentLine);

    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * 20}
            dy={20}
            textAnchor="middle"
            // fill="#666"
          >
            {line}
          </text>
        ))}
      </g>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "20px",
          display: "flex",
          marginBottom: "100px",
          gap: "10px",
        }}
      >
        <BarChartIcon />
        <Typography sx={{ textAlign: "center", fontSize: "22px" }}>
          {label}
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="name"
            tick={renderCustomizedXAxisTick}
            orientation={"bottom"}
            angle={90}
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#006400 "
            // label={{ value: "Number", angle: -90, position: "insideLeft" }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#9ACD32"
            // label={{ value: "Area", angle: 90, position: "insideRight" }}
          />
          {/* <Bar
            dataKey="uv"
            barSize={30}
            fill="#8884d8"
            label={renderCustomBarLabel}
          /> */}
          <Tooltip
          // content={
          //   <CustomTooltip
          //     active={undefined}
          //     payload={undefined}
          //     label={undefined}
          //   />
          // }
          />
          <Legend
            width={100}
            wrapperStyle={{
              top: 10,
              right: 500,
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
              borderRadius: 3,
              lineHeight: "30px",
            }}
          />

          <Bar
            yAxisId="left"
            dataKey="uv"
            fill="#006400"
            name="Number"
            barSize={40}
          />
          <Bar
            yAxisId="right"
            dataKey="pv"
            fill="#9ACD32"
            name="Area"
            barSize={40}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarChartComponent;
