import { Box } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import HeaderSection from "src/components/common/HeaderSection";
import { getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import { path } from "src/constants/path";

type AuthLayoutProps = {
  children?: ReactNode;
};
const AuthLayout = ({ children }: AuthLayoutProps) => {
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setShowHeader(
      ![path.signIn, path.forgetPassword].includes(location.pathname)
    );
  }, [location.pathname]);

  const styles = getStyles<StylesClasses>(defaultStyles);
  return (
    <Box
      {...styles("wrapper", {
        ...(!showHeader && defaultStyles.noHeader),
      })}
    >
      {showHeader && <HeaderSection title="User Registration" />}
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
