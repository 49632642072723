import { Box, Divider } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import { Link } from "react-router-dom";

type StatsCardProps = {
  title: string;
  value: number | string;
  variant?: "horizontal" | "vertical";
  icon?: string;
  area?: string;
  link?: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const StatsCard = ({
  customStyles,
  variant = "horizontal",
  title,
  value,
  area,
  link,
}: StatsCardProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Link to={link || ""} style={{ textDecoration: "none" }}>
      <Box {...styles("valueCard")}>
        <Box {...styles("title")}>{title}</Box>
        <Divider {...styles("divider")} />
        <Box {...styles("iconHalf")}>
          {area && (
            <Box {...styles("value")}>
              <Box {...styles("subHeading")}>AREA(Ha)</Box>
              {area}
              {/* <Box component="span" {...styles("unit")}>
              Ha
            </Box> */}
            </Box>
          )}
          <Box {...styles("value")}>
            <Box {...styles("subHeading")}>NUMBERS</Box>
            {value}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default StatsCard;
