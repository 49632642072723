import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { Button, Input } from "src/components/common/FormComponents";
import errorMessage from "src/constants/errorMessage";
import { getStyles } from "src/styles/theme";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type ForgetPasswordProps = {
  userEmail: string;
};

const ForgetPassword = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const { control, formState, handleSubmit } = useForm<ForgetPasswordProps>({
    mode: "onTouched",
    defaultValues: {
      userEmail: "",
    },
  });

  const { errors } = formState;

  const handleFormSubmit = (data: ForgetPasswordProps) => {};

  return (
    <Box {...styles("container")}>
      <Box {...styles("logInWrapper")}>
        <Box {...styles("logo")}>
          <Box component={"img"} src="/assets/images/coloredLeafIcon.png"></Box>
          <Box {...styles("title")}>GREEN CREDIT</Box>
        </Box>
        <Box {...styles("heading")}>Recover your Password</Box>
        <Box {...styles("description")}>
          <Box component={"span"} {...styles("spanStyle")}>
            Enter the email{" "}
          </Box>{" "}
          that you used when register to recover your password. You will receive
          a{" "}
          <Box component={"span"} {...styles("spanStyle")}>
            password reset link.{" "}
          </Box>
        </Box>

        <Box
          {...styles("emailField")}
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Input
            name="userEmail"
            label="Email"
            placeholder="Enter your email"
            control={control}
            rules={{
              required: errorMessage.required,
              pattern: {
                value: regex.email,
                message: errorMessage.invalidEmail,
              },
            }}
            errors={errors}
            height="52px"
          />

          <Button
            text="Send Link"
            type="submit"
            customStyles={{
              root: defaultStyles.loginBtn,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
