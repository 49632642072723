import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  container: {
    background: "#F4F5F7",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
  },
  outletContainer: {
    padding: "24px",
  },
  outletWrapper: {
    width: "100%",
  },
});

export default styles;
