import { Box, Button, Collapse, Typography } from "@mui/material";
import { useQuery } from "react-query";
import StatsCard from "src/components/common/StatsCard";

import defaultStyles, { StylesClasses } from "../styles";
import { getStyles } from "src/styles/theme";
import {
  getLandDashboardDetails,
  getTableDashboardDetails,
} from "src/utils/api/dashboard";
import { DashBoardTable } from "src/components/common/DashBoardTable";
import {
  downloadSheet,
  exportSelectedLandTable,
  exportTableToXlsx,
  formatData,
  formatDataForBarChart,
} from "src/utils/helper";
import Fallback from "src/components/common/Fallback";
import { useEffect, useState } from "react";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import BarChartComponent from "src/components/common/BarComponent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RenderTable from "../../Home/RenderTable";
import { costEstimateDashboardtableColumns } from "src/constants/tableColumns";
import { AxiosResponse } from "axios";
import { SelectedLandTable } from "src/components/common/DashBoardTable/selectedLand";

const SelectedLandDashboard = () => {
  const [isTabLoading, setIsTabLoading] = useState(true);

  const [states, setStates] = useState<any>([]);

  const [tableRows, setTableRows] = useState([]);

  const [filtersState, setFiltersState] = useState([]);

  const [queryParams, setQueryParams] = useState<{
    projectStateIds?: string[];
    type: string;
  }>({
    projectStateIds: undefined,
    type: "registrationStatus",
  });

  const [boxQueryParams, setBoxQueryParams] = useState<{
    projectStateIds?: string[];
    type: string;
  }>({
    projectStateIds: undefined,
    type: "registrationStatusBoxes",
  });

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
  });

  const { data: dashboard, isLoading: dashboardLoading } = useQuery(
    ["selectedDashboard", boxQueryParams],
    () => getTableDashboardDetails(boxQueryParams),
    {
      onSuccess: () => {},
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const { data: tableDashboard, isLoading } = useQuery(
    ["selectedLandDataTable", queryParams],
    () => getTableDashboardDetails(queryParams),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res?.data?.stats.map((items, idx) => ({
            id: idx,
            sno: idx + 1,
            state: items.state,
            totalAreaApproved: items.totalAreaApproved,
            totalAreaAvailable: items.totalAreaAvailable,
            totalAreaRegistered: items.totalAreaRegistered,
            totalAreaSelected: items.totalAreaSelected,
            totalNumberApproved: items.totalNumberApproved,
            totalNumberAvailable: items.totalNumberAvailable,
            totalNumberRegistered: items.totalNumberRegistered,
            totalNumberSelected: items.totalNumberSelected,
          }))
        );
        setIsTabLoading(false);
      },
      onError: () => {},
      refetchOnMount: true,
    }
  );

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const dashboardTableData: DashBoardTable = {
    topHeader: [
      "",
      "Registered Eco-Restoration",
      "Approved Eco-Restoration by ICFRE",
      "Eco-Restoration Selected by Entities",
      "Eco-Restoration Available for Selection",
    ],
    header: [
      "S.No",
      "State",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
    ],
    data: tableRows,
  };
  const styles = getStyles(defaultStyles);

  useEffect(() => {
    setFiltersState(dashboard?.data?.state);
  }, [dashboard]);

  useEffect(() => {
    setIsTabLoading(true);
  }, []);

  const [tableState, setTableState] = useState(false);

  const formattedData = formatDataForBarChart(dashboard?.data?.stats);

  return (
    <>
      <Box {...styles("topHeader")}>
        {!dashboardLoading ? (
          <Box sx={{ width: "75vw", marginBottom: "20px", marginTop: "80px" }}>
            <Box sx={{ width: "80%", marginRight: "80px" }}>
              <BarChartComponent
                data={formattedData}
                label={
                  "Status of Available and Selected Eco-Restoration Blocks"
                }
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box {...styles("statsCardWrapper", { marginTop: "20px" })}>
          {dashboard?.data?.stats?.map((stat, index) => (
            <StatsCard
              key={index}
              title={`${stat?.description?.toUpperCase()}`}
              value={`${stat?.number}`}
              area={stat?.totalArea}
              customStyles={{ valueCard: styles("statsCard").sx }}
              link={""}
            />
          ))}
        </Box>
      </Box>
      {isLoading || isTabLoading ? (
        <Fallback />
      ) : (
        <Box {...styles("dashboardTableWrapper")}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" {...styles("heading")}>
              Status of Selected and Available Eco-Restoration Blocks
            </Typography>
            <Button
              variant="outlined"
              onClick={() => setTableState(!tableState)}
            >
              {tableState ? (
                <>
                  Compress
                  <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
                </>
              ) : (
                <>
                  Expand
                  <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
                </>
              )}
            </Button>
          </Box>
          <Collapse in={tableState}>
            <Button
              variant="outlined"
              onClick={() =>
                exportSelectedLandTable(
                  dashboardTableData.data,
                  dashboardTableData.header,
                  dashboardTableData.topHeader
                )
              }
              sx={{ float: "right", marginBottom: "20px" }}
            >
              Export
            </Button>
            <SelectedLandTable {...dashboardTableData} />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default SelectedLandDashboard;
