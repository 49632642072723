import { Option, StepperConfig } from "src/types/common";

export const indexing = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
];

export const devTypes = {
  INDIVIDUAL: "INDIVIDUAL",
  ORGANIZATION: "ORGANIZATION",
  OTHER: "OTHER",
};

export const ownerShipRecordtype = {
  "03790557-5c1f-4441-8bda-9304374865c0": "NOC",
  "fb2c6d5d-3265-4539-a180-bfde07748eed": "Lease",
};

export enum CHIP_STATUS {
  DRAFT = "draft",
  IMAGE_PENDING = "inactive",
  DECLARATION_PENDING = "draft",
  VERIFICATION_PENDING = "draft",
  REVIEWED = "active",
  REVIEW_FAILED = "error",
  COMPLETED = "active",
  APPROVED = "active",
  REJECTED = "error",
  FLAGGED = "inactive",
}

export const SidebarMenu = [
  {
    icon: "/assets/svg/marketIcon.svg",
    iconSelected: "/assets/svg/marketIcon.svg",
    text: "Admin Dashboard",
    path: "/market-watch",
    id: "dashboard",
  },
  {
    icon: "/assets/svg/folderIcon.svg",
    iconSelected: "/assets/svg/selectedFolderIcon.svg",
    text: "Eco-Restoration Blocks",
    path: "/registered-lands",
    id: "projects",
  },
  {
    icon: "/assets/svg/folderIcon.svg",
    iconSelected: "/assets/svg/selectedFolderIcon.svg",
    text: "Cost Estimates",
    path: "/",
    id: "projects",
  },
  {
    icon: "/assets/svg/folderIcon.svg",
    iconSelected: "/assets/svg/selectedFolderIcon.svg",
    text: "Fund Application",
    path: "/fund-transfer",
    id: "fundTransfer",
  },
  {
    icon: "/assets/svg/folderIcon.svg",
    iconSelected: "/assets/svg/selectedFolderIcon.svg",
    text: "Progress Details",
    path: "/progress-details",
    id: "progress-details",
  },
  {
    icon: "/assets/svg/users.svg",
    iconSelected: "/assets/svg/users.svg",
    text: "Implementing Agency",
    path: "/implementing-agency",
    id: "market-watch",
  },
  {
    icon: "/assets/svg/users.svg",
    iconSelected: "/assets/svg/users.svg",
    text: "Entity/ GCA",
    path: "/users",
    id: "market-watch",
  },
];

export const helper = [
  {
    label: "A",
    value: "A",
  },
];

export enum PROJECT_DOCUMENT_TYPE_MAP {
  SELF_DECLARATION_FORM = "Self declaration form",
  LAND_OWNERSHIP = "Land Ownership",
  PLOT_LAYOUT = "Plot layout",
  AREA_SHAPE = "Area shape",
  PROJECT_LAYOUT = "Project layout",
  BANK_PASSBOOK = "Bank passbook",
  BANK_CHEQUE = "bank cheque",
  BANK_ACCOUNT_RECORD = "Bank account record",
  SAPLING_RECEIPT = "Sapling receipt",
  PAYMENT_RECEIPT = "Payment receipt",
  WORKING_PERMIT = "Working permit",
}

export enum PROJECT_DOCUMENT_TYPE {
  SELF_DECLARATION_FORM = "SELF_DECLARATION_FORM",
  LAND_OWNERSHIP = "LAND_OWNERSHIP",
  PLOT_LAYOUT = "PLOT_LAYOUT",
  AREA_SHAPE = "AREA_SHAPE",
  PROJECT_LAYOUT = "PROJECT_LAYOUT",
  BANK_PASSBOOK = "BANK_PASSBOOK",
  BANK_CHEQUE = "BANK_CHEQUE",
  BANK_ACCOUNT_RECORD = "BANK_ACCOUNT_RECORD",
  SAPLING_RECEIPT = "SAPLING_RECEIPT",
  PAYMENT_RECEIPT = "PAYMENT_RECEIPT",
  WORKING_PERMIT = "WORKING_PERMIT",
}

export const userTypeOptions = [
  {
    label: "Green Credit Project Developer",
    value: "greenCreditProjectDeveloper",
  },
  { label: "Certifiers", value: "certifier" },
  { label: "Verifiers", value: "verifier" },
  { label: "Buyers", value: "buyers" },
];

export const DOCUMENT_ENUM = {
  CIN: "CIN",
  AADHAAR: "AADHAAR",
  GST: "GST",
  PAN: "PAN",
};

export const ROLES = {
  REVIEWER: "Reviewer",
  APPROVER: "Approver",
  INDIVIDUAL: "Individual",
  ENTITY: "Entity",
  ORGANIZATION: "Entity",
};

export enum PROJECT_STATUS {
  DRAFT = "In Review",
  IMAGE_PENDING = "Image Pending",
  DECLARATION_PENDING = "Declaration Pending",
  VERIFICATION_PENDING = "Verification Pending",
  REVIEWED = "Reviewed",
  COMPLETED = "Completed",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  FLAGGED = "Flagged",
  REVIEW_FAILED = "REVIEW_FAILED",
}

export enum PROJECT_STATUS_ALL {
  DRAFT = "DRAFT",
  IMAGE_PENDING = "IMAGE_PENDING",
  DECLARATION_PENDING = "DECLARATION_PENDING",
  VERIFICATION_PENDING = "VERIFICATION_PENDING",
  REVIEWED = "REVIEWED",
  COMPLETED = "COMPLETED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  FLAGGED = "FLAGGED",
  REVIEW_FAILED = "REVIEW_FAILED",
  UNDER_QUERY = "UNDER_QUERY",
}

export const reason = {
  REJECTED: {
    heading: "Reason For Rejection",
    text: "Text for reason",
  },
};

export enum DECLERATION_TYPE {
  COMPLIANCE = "COMPLIANCE",
  VOLUNTARY = "VOLUNTARY",
}
export enum SPECIES_TYPE {
  FORESTRY_SPECIES = "FORESTRY_SPECIES ",
  HORTICULTURE_SPECIES = "HORTICULTURE_SPECIES",
}

export const declerationTypeOptions = [
  {
    label: "Voluntary",
    value: DECLERATION_TYPE.VOLUNTARY,
  },
  {
    label: "Compliance",
    value: DECLERATION_TYPE.COMPLIANCE,
  },
];
export const speciesTypeOptions = [
  {
    label: "FORESTRY SPECIES",
    value: SPECIES_TYPE.FORESTRY_SPECIES,
  },
  {
    label: "HORTICULTURE SPECIES",
    value: SPECIES_TYPE.HORTICULTURE_SPECIES,
  },
];

export const USER_TYPE = {
  INDIVIDUAL: "INDIVIDUAL",
  ENTITY: "ORGANIZATION",
};

export const documentUpload = [
  {
    label: "CIN/GSTIN UIN",
    value: DOCUMENT_ENUM.CIN,
  },
  {
    label: "PAN",
    value: DOCUMENT_ENUM.PAN,
  },
];

export const devTypeOptions = [
  {
    label: "Individual",
    value: USER_TYPE.INDIVIDUAL,
  },
  { label: "Entity", value: USER_TYPE.ENTITY },
];

export const countryCode = [
  {
    label: "+91",
    value: "+91",
  },
];

export const rainFallZoneOption = [
  {
    label: "500-1000mm",
    value: "500-1000mm",
  },
  {
    label: "200-600mm",
    value: "200-600mm",
  },
  {
    label: "300-700mm",
    value: "300-700mm",
  },
];

export const industryType = [
  {
    label: "Utility",
    value: "Utility",
  },
];

export const projectDuration = [
  {
    label: "10 Year",
    value: "10 Year",
  },
  {
    label: "20 Year",
    value: "20 Year",
  },
  {
    label: "30 Year",
    value: "30 Year",
  },
];

export const userType = {
  // not using currently
  GREEN_CREDIT_DEVELOPER: "Green Credit Project Developer",
  CERTIFIERS: "certifier",
  VERIFIERS: "verifier",
  BUYERS: "buyers",
};

export const stepperConfig: StepperConfig[] = [
  {
    userType: "Green Credit Project Developer",
    devType: "INDIVIDUAL",
    steps: 3,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Type Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Personal Details",
        isActive: true,
        route: "path.personalDetails",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Aadhaar Verification",
        isActive: false,
        route: "path.aadhaarVerification",
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: "path.addressDetails",
      },
    ],
  },
  {
    userType: "Green Credit Project Developer",
    devType: "ORGANIZATION",
    steps: 4,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Entity Details", // previously It was organisation
        isActive: true,
        route: "path.organisationDetails",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Document Upload",
        isActive: false,
        route: "path.documentUpload",
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: "path.addressDetails",
      },
    ],
  },
  {
    userType: "certifier",
    devType: "INDIVIDUAL",
    steps: 3,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Personal Details",
        isActive: true,
        route: "path.onboarding",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: "path.onboarding",
      },
    ],
  },
  {
    userType: "certifier",
    devType: "ORGANIZATION",
    steps: 4,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Organization Details",
        isActive: true,
        route: "path.onboarding",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: "path.onboarding",
      },
    ],
  },
  {
    userType: "verifier",
    devType: "INDIVIDUAL",
    steps: 3,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Personal Details",
        isActive: true,
        route: "path.onboarding",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: "path.onboarding",
      },
    ],
  },
  {
    userType: "verifier",
    devType: "ORGANIZATION",
    steps: 4,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Organization Details",
        isActive: true,
        route: "path.onboarding",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: "path.onboarding",
      },
    ],
  },
  {
    userType: "buyers",
    devType: "INDIVIDUAL",
    steps: 3,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Personal Details",
        isActive: true,
        route: "path.onboarding",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: "path.onboarding",
      },
    ],
  },
  {
    userType: "buyers",
    devType: "ORGANIZATION",
    steps: 4,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 2,
        isCompleted: false,
        name: "Organization Details",
        isActive: true,
        route: "path.onboarding",
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: "path.onboarding",
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: "path.onboarding",
      },
    ],
  },
];

export enum ProjectType {
  WATER_CONSERVATION = "WATER_CONSERVATION",
  TREE_PLANTATION = "TREE_PLANTATION",
  LAND_LEASED = "LAND_LEASED",
}

export enum ImageType {
  SELF_DECLARATION_FORM = "SELF_DECLARATION_FORM",
  LAND_OWNERSHIP = "LAND_OWNERSHIP",
  PLOT_LAYOUT = "PLOT_LAYOUT",
  BANK_PASSBOOK = "BANK_PASSBOOK",
  BANK_CHEQUE = "BANK_CHEQUE",
  SAPLING_RECEIPT = "SAPLING_RECEIPT",
  PAYMENT_RECEIPT = "PAYMENT_RECEIPT",
}

export const organizationOptions: Option[] = [
  {
    label: "Farmer Producer Organisation(FPO)",
    value: "FPO",
  },
  {
    label: "Forest Management Committee(FMC)",
    value: "FMC",
  },
  {
    label: "Joint Forest Management Committee(JFMC)",
    value: "JFMC",
  },
  {
    label: "Self Help Group(SHG)",
    value: "SHG",
  },
  {
    label: "Eco-Development committee(EDC)",
    value: "EDC",
  },
  {
    label: "Urban Local Bodies(ULB)",
    value: "ULB",
  },
  {
    label: "Non-Government Organisation(NGO)",
    value: "NGO",
  },
  {
    label: "Enterprise",
    value: "enterprise",
  },
  {
    label: "Company",
    value: "company",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const incomeRageOptions = [
  { label: "4000-5000 lpa", value: "4-5" },
  { label: "5000-6000 lpa", value: "5-6" },
  { label: "6000-7000 lpa", value: "6-7" },
];
export const institutionTypeOptions = [
  { label: "Cooperative", value: "Cooperative" },
  { label: "fp", value: "fp" },
  { label: "FMC", value: "FMC" },
  { label: "SHGs", value: "SHGs" },
  { label: "Eco-development committee", value: "Eco-development" },
  { label: "Urban", value: "Urban" },
  { label: "rural", value: "rural" },
];
export const stateOptions = [
  { label: "delhi", value: "delhi" },
  { label: "haryana", value: "haryana" },
  { label: "up", value: "up" },
];

export const ladUseTypeOption = [
  { label: "agricultural", value: "agricultural" },
  { label: "urban ", value: "urban " },
  { label: "rural", value: "rural" },
];

export const genderTypeOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const numbersOption = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
];

export const tooltipContent = {
  reject: {
    title: "Reason For Rejection",
    desc: "Lorem ipsum dolor sit amet consectetur. Adipiscing molestie duis leo tortor tellus.",
  },
};

export enum PROJECT_TYPE {
  LAND_LEASED = "LAND_LEASED",
  TREE_PLANTATION = "TREE_PLANTATION",
}

export const RoleIds = {
  "Green Credit Project Developer": "b9c4e0f5-c062-4ad5-9cb3-fc3e24e918c1",
  Approver: "36a8cd90-9fa4-4c08-ab8f-f8faf96c64f1",
  Reviewer: "bf03a589-86c0-4f1f-be20-9ebec7c7d800",
};

export const registeredEntitiesTabs = [
  { label: "All", value: undefined },
  {
    label: "Cost Estimate Pending",
    value: "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
  },
  {
    label: "Verify Cost Estimate",
    value: "VERIFY_DEMAND_NOTE",
  },
  { label: "SNO Query", value: "NODAL_UNDER_QUERY" },
  { label: "Admin Query", value: "UNDER_QUERY" },
  { label: "Payment Pending", value: "PAYMENT_PENDING" },
  { label: "Verification of Payment", value: "VERIFICATION_PENDING" },
  { label: "Payment Received", value: "APPROVED" },
  // { label: "Rejected Payment", value: "REJECTED" },
];

export const registeredLandTabs = [
  { label: "Registered", value: undefined },
  { label: "Verification Pending", value: "VERIFICATION_PENDING" },
  { label: "Approved", value: "APPROVED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "SNO Query", value: "NODAL_UNDER_QUERY" },
  { label: "Admin Query", value: "UNDER_QUERY" },
];

export const userTabData = [
  { label: "Implementing Agency", value: "Implementing Agency" },
  {
    label: "Entity/ Green Credit Applicant",
    value: "Entity/ Green Credit Applicant",
  },
];

export const statusMessages2 = {
  UNDER_QUERY: "UNDER_QUERY",
  VERIFY_DEMAND_NOTE: "UNDER_VERIFICATION",
  GENERATE_DEMAND_NOTE: "COST_ESTIMATE_PENDING",
  REGENERATE_DEMAND_NOTE: "RESUBMIT_COST_ESTIMATE",
  APPROVED: "PAYMENT_DONE",
  NODAL_VERIFY_DEMAND_NOTE: "UNDER_SNO_VERIFICATION",
  VERIFICATION_PENDING: "UNDER_PAYMENT_VERIFICATION",
};

export const ExportStatusMessages2 = {
  UNDER_QUERY: "UNDER_QUERY",
  VERIFY_DEMAND_NOTE: "UNDER_VERIFICATION",
  GENERATE_DEMAND_NOTE: "COST_ESTIMATE_PENDING",
  REGENERATE_DEMAND_NOTE: "RESUBMIT_COST_ESTIMATE",
  APPROVED: "PAYMENT_DONE",
  NODAL_VERIFY_DEMAND_NOTE: "UNDER_SNO_VERIFICATION",
  VERIFICATION_PENDING: "UNDER_PAYMENT_VERIFICATION",
  NODAL_UNDER_QUERY: "UNDER_SNO_QUERY",
  PAYMENT_PENDING: "PAYMENT_PENDING",
};

export const fundPaymentTabs = [
  {
    label: "ALL",
    value: undefined,
  },
  {
    label: "Fund Application",
    value: "RAISED",
  },
  {
    label: "Admin Query",
    value: "UNDER_QUERY",
  },
  {
    label: "Payment Released",
    value: "APPROVED",
  },
  {
    label: "Payment Acknowledged Receipt",
    value: "PAYMENT_ACKNOWLEDGED",
  },
];
