import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: 35,
  },

  content: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    fontSize: "16px",
    flexWrap: "wrap",
    // fontWeight: "medium",
    fontWeight: "black",
  },

  label: {
    margin: "5px 0",
    flexBasis: "45%",
    marginRight: "20px",
    fontWeight: "black",
  },

  value: {
    margin: "5px 0",
    fontWeight: "medium",
    flexWrap: "wrap",
  },

  subHeading: {
    margin: "20px 0 10px",
  },
  subheader: {
    fontSize: "18px",
    margin: "10px 0 5px",
    fontWeight: "extrabold",
  },

  mainHeading: {
    fontSize: "22px",
    fontWeight: "bold",
  },

  divider: {
    flexGrow: 0.9,
    borderTop: 2,
    height: 0,
    borderColor: "#e8e9eb",
    marginBottom: "5px",
  },

  imageWrapper: {
    // marginLeft: 180,
    height: "auto",
    width: "200px",
  },
  sectionThreeWrapper: {
    marginTop: 20,
  },

  link: {
    margin: "0",
  },

  imagesWrapper: {
    marginTop: "10px",
  },

  multiValLabel: {
    fontSize: "16px",
    margin: "0",
  },

  multiValue: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
  },

  docWrapper: {
    marginTop: "10px",
  },
});

export default styles;
