import { Box, CircularProgress, Button as MuiButton } from "@mui/material";
import React from "react";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyle, { StylesClasses } from "./styles";

type ButtonProps = {
  text: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  normalFontWeight?: boolean;
  variant?: "contained" | "outlined" | "text";
  customStyles?: CustomStyles<StylesClasses>;
  src?: string;
  type?: "button" | "reset" | "submit";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
};

export const Button = ({
  text,
  onClick,
  loading,
  disabled,
  style,
  textStyle,
  normalFontWeight,
  variant = "contained",
  type = "button",
  customStyles,
  src,
  color = "primary",
}: ButtonProps) => {
  const styles = getStyles<StylesClasses>(defaultStyle, customStyles);

  return (
    <>
      <MuiButton
        {...styles(["root", `${variant}`])}
        color={color}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {loading ? (
          <>
            {text}{" "}
            <CircularProgress
              {...styles("circle")}
              size="1.5rem"
              color="secondary"
            />
          </>
        ) : (
          <Box {...styles("iconButtonContainer")}>
            {src && (
              <Box
                component="img"
                src={src}
                {...styles("buttonIcon")}
                alt={src}
              />
            )}
            {text}
          </Box>
        )}
      </MuiButton>
    </>
  );
};
