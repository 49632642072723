import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  FormControlProps,
  FormHelperText,
  InputBase,
  InputLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
  UseFormGetValues,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import { getError } from "src/utils/common";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

type PasswordProps<T> = UseControllerProps<T> &
  FormControlProps & {
    label: string;
    errors?: FieldErrors;
    customStyles?: CustomStyles<StylesClasses>;
    showHelperText?: boolean;
    getValues?: UseFormGetValues<T>;
    placeholder?: string;
    height?: string;
  };

const hasError = (
  value: string,
  error: boolean,
  isRegexMatched: boolean = false
) => {
  if (!error && !value.length) {
    return "custom.text.light";
  }

  if (isRegexMatched && value.length) {
    return "primary.main";
  }

  return "error.main";
};

const Password = <T extends FieldValues>({
  name,
  control,
  height = "40px",
  label,
  variant = "standard",
  fullWidth = true,
  disabled = false,
  errors,
  rules,
  customStyles,
  className,
  showHelperText = false,
  placeholder = "",
  getValues,
  ...rest
}: PasswordProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [showPassword, setShowPassword] = useState(false);
  const error = getError(name, errors);
  const { passwordCriteria } = regex;
  const showError = !!error;

  return (
    <Controller
      render={({ field }) => (
        <Box {...styles("wrapper")}>
          <InputLabel
            shrink
            {...styles("label")}
            required={!!rules?.required}
            disabled={disabled}
            htmlFor={name}
          >
            {label}
          </InputLabel>
          <InputBase
            id={name}
            {...styles("input")}
            fullWidth={fullWidth}
            disabled={disabled}
            placeholder={placeholder}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={showPassword ? "text" : "password"}
            inputRef={field.ref}
            error={showError}
            endAdornment={
              <InputAdornment position="start" {...styles("inputAdornment")}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  {...styles("iconBtn")}
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon
                      {...styles("icon", {
                        color: error ? "error.main" : "inherit",
                      })}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      {...styles("icon", {
                        color: error ? "error.main" : "inherit",
                      })}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && (
            <FormHelperText {...styles("formHelperText")}>
              {error.message}
            </FormHelperText>
          )}
          {showHelperText && (
            <FormHelperText {...styles("customError")}>
              {[
                {
                  regexCase: passwordCriteria.eightChar,
                  text: "8 Characters |",
                },
                { regexCase: passwordCriteria.upper, text: "1 Uppercase |" },
                { regexCase: passwordCriteria.lower, text: "1 Lowercase |" },
                { regexCase: passwordCriteria.digit, text: "1 Digit |" },
                {
                  regexCase: passwordCriteria.specialChar,
                  text: "1 Special Character(!@$$%^&&*...)",
                },
              ].map(({ regexCase, text }) => (
                <Box
                  key={text}
                  {...styles("pwdValidationMsg", {
                    color: hasError(
                      getValues(name),
                      !!error,
                      regexCase.test(getValues(name))
                    ),
                  })}
                >
                  {text}
                </Box>
              ))}
            </FormHelperText>
          )}
        </Box>
      )}
      name={name}
      rules={rules}
      control={control}
      {...rest}
    />
  );
};
export default Password;
