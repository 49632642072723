import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px 5%",
    boxSizing: "border-box",
    padding: "13px",
    minWidth: "300px",
  },
  title: {
    color: "custom.text.label",
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
  },
  description: {
    color: "custom.text.label",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
  },
  btnDimensions: {
    width: "200px",
  },

  cancelBox: {
    position: "absolute",
    top: "15px",
    right: "13px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },

  closeIcon: {
    marginLeft: "10px",
    color: "black",
    fontSize: "30px",
  },

  iconWrapper: {
    margin: "auto",
  },
  img: {},
});

export default styles;
