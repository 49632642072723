import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./styles";

const Fallback = () => {
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress size="5rem" />
    </Box>
  );
};

export default Fallback;
