import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { DashboardWrapper } from "src/components/common/DashboardWrapper";
import { Input } from "src/components/common/FormComponents";
import Snackbar from "src/components/common/Snackbar";
import ViewImages from "src/components/common/ViewImage";
import { MyProfileType, myProfileValue } from "src/constants/sampleData";
import { getStyles } from "src/styles/theme";
import { getMyProfileData } from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "./styles";

const UserDetails = () => {
  const height = "52px";
  const location = useLocation();
  const userId = location?.state?.userId || "";
  const [isError, setIsError] = useState(false);

  const { data: myProfileData } = useQuery(
    ["myProfile", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (err: AxiosError) => {
        setIsError(true);
      },
      enabled: !!userId,
    }
  );

  const userData = myProfileData?.data;

  const currAddress = userData?.currentAddress;

  const userDocs = _.groupBy(userData?.userDocuments, "type");
  console.log(userDocs);
  const { control } = useForm<MyProfileType>({
    mode: "onTouched",
    defaultValues: { ...myProfileValue },
  });

  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <DashboardWrapper
      title="User Profile"
      showBackBtn={true}
      backPath={
        userData?.userType === "ORGANIZATION"
          ? "/users"
          : "/implementing-agency"
      }
    >
      <Box {...styles("wrapper")}>
        <Box {...styles("childWrapper")}>
          <Box {...styles("heading")}>personal Details</Box>
          <Box {...styles("container")}>
            {userData?.userType === "ORGANIZATION" && (
              <Box {...styles("input")}>
                <Input
                  name="authName"
                  label="Entity Type"
                  placeholder="Entity Type"
                  control={control}
                  value={userData?.entityTypeName}
                  customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  height={height}
                  readOnly
                />
              </Box>
            )}

            <Box {...styles("input")}>
              <Input
                name="firstName"
                label={
                  userData?.userType === "ORGANIZATION"
                    ? "Entity Name"
                    : "Agency Name"
                }
                placeholder="Enter full name"
                control={control}
                value={
                  userData?.userType === "ORGANIZATION"
                    ? userData?.firstName
                    : userData?.forestDetails?.name
                }
                height={height}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
                readOnly
              />
            </Box>

            {userData?.userType === "ORGANIZATION" ? (
              <Box {...styles("input")}>
                <Input
                  name="authName"
                  label="Authorised Representative Name"
                  placeholder="Authorised Representative Name"
                  control={control}
                  value={userData?.nameOfAuthorizedPerson}
                  customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  height={height}
                  readOnly
                />
              </Box>
            ) : (
              <Box {...styles("input")}>
                <Input
                  name="authName"
                  label={
                    userData?.userType === "INDIVIDUAL"
                      ? "Nodal Officer Name"
                      : "Implementing Officer Name"
                  }
                  placeholder="Authorised Representative Name"
                  control={control}
                  value={userData?.firstName}
                  customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  height={height}
                  readOnly
                />
              </Box>
            )}
            <Box {...styles("input")}>
              <Input
                name="authDesig"
                label="Designation"
                placeholder="Designation"
                control={control}
                value={userData?.designation}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
                height={height}
                readOnly
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <Input
                name="email"
                label="Email"
                placeholder="Enter your email"
                value={userData?.email}
                control={control}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
                readOnly
                height={height}
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <Input
                name="phoneNumber"
                label="Phone Number"
                placeholder="Enter your phoneNumber"
                value={userData?.phoneNumber}
                control={control}
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
                readOnly
                height={height}
              />
            </Box>
            {userData?.userType === null && (
              <>
                <Box {...styles("input")}>
                  <Input
                    name="authDesig"
                    label="Division"
                    placeholder="Enter Division"
                    control={control}
                    value={userData?.division}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    height={height}
                    readOnly
                  />
                </Box>
                <Box {...styles("input")}>
                  <Input
                    name="authDesig"
                    label="District"
                    placeholder="Enter District"
                    control={control}
                    value={userData?.userDistrict}
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    height={height}
                    readOnly
                  />
                </Box>
              </>
            )}
          </Box>
          {userData?.userType === "ORGANIZATION" && (
            <Box {...styles("childWrapper")}>
              <Box {...styles("heading")}>ID Proof Number and Documents</Box>
              <Box {...styles("container")}>
                <Box {...styles("input")}>
                  <Input
                    name="currentAddress.addressLine1"
                    label="ID Proof Number"
                    placeholder="ID Proof Number"
                    control={control}
                    value={userData?.identityProofNumber}
                    height={height}
                    readOnly
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  />
                </Box>
              </Box>
              <ViewImages
                fileUrl={userData?.identityProofImageUrl?.fileUrl}
                label={"Identity Proof Image"}
              />
              {userData?.organizationCertificateImageUrl?.fileUrl && (
                <ViewImages
                  fileUrl={userData?.organizationCertificateImageUrl?.fileUrl}
                  label={"organization Certificate Image"}
                />
              )}
            </Box>
          )}
        </Box>
        <Box {...styles("childWrapper")}>
          <Box {...styles("heading")}>Registered address</Box>
          <Box {...styles("container")}>
            {(userData?.userType === "INDIVIDUAL" ||
              userData?.userType === null) && (
              <>
                <Box {...styles("input")}>
                  <Input
                    name="currentAddress.district"
                    label="state"
                    placeholder="Enter district"
                    control={control}
                    value={currAddress?.state}
                    height={height}
                    readOnly
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  />
                </Box>
                <Box {...styles("input")}>
                  <Input
                    name="currentAddress.district"
                    label="District"
                    placeholder="Enter district"
                    control={control}
                    value={currAddress?.district}
                    height={height}
                    readOnly
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  />
                </Box>
                <Box {...styles("input")}>
                  <Input
                    name="currentAddress.district"
                    label="City"
                    placeholder="Enter district"
                    control={control}
                    value={currAddress?.city}
                    height={height}
                    readOnly
                    customStyles={{ wrapper: defaultStyles.selectWrapper }}
                  />
                </Box>
              </>
            )}
            <Box {...styles("input")}>
              <Input
                name="currentAddress.addressLine1"
                label={
                  userData?.userType === "INDIVIDUAL"
                    ? "Street Address"
                    : "Address"
                }
                placeholder="Enter street address"
                control={control}
                value={currAddress?.addressLine1}
                height={height}
                readOnly
                customStyles={{ wrapper: defaultStyles.selectWrapper }}
              />
            </Box>
            {userData?.userType === "INDIVIDUAL" &&
              userData?.forestDetails?.bankDetail?.bankName && (
                <Box {...styles("childWrapper")}>
                  <Box {...styles("heading")}>Bank Details</Box>
                  <Box {...styles("container")}>
                    <Box {...styles("input")}>
                      <Input
                        name="currentAddress.district"
                        label="Bank Name"
                        placeholder="Enter district"
                        control={control}
                        value={userData?.forestDetails?.bankDetail?.bankName}
                        height={height}
                        readOnly
                        customStyles={{ wrapper: defaultStyles.selectWrapper }}
                      />
                    </Box>
                    <Box {...styles("input")}>
                      <Input
                        name="currentAddress.district"
                        label="Account Number"
                        placeholder="Enter district"
                        control={control}
                        value={
                          userData?.forestDetails?.bankDetail?.accountNumber
                        }
                        height={height}
                        readOnly
                        customStyles={{ wrapper: defaultStyles.selectWrapper }}
                      />
                    </Box>
                    <Box {...styles("input")}>
                      <Input
                        name="currentAddress.district"
                        label="IFSC Code"
                        placeholder="Enter district"
                        control={control}
                        value={userData?.forestDetails?.bankDetail?.ifscCode}
                        height={height}
                        readOnly
                        customStyles={{ wrapper: defaultStyles.selectWrapper }}
                      />
                    </Box>
                    {userData?.cancelChequeImageUrl?.fileUrl && (
                      <ViewImages
                        fileUrl={userData?.cancelChequeImageUrl?.fileUrl}
                        label={"Cancelled cheque Image"}
                      />
                    )}
                  </Box>
                </Box>
              )}
            {userData?.userType === null && (
              <Box {...styles("childWrapper")}>
                <Box {...styles("heading")}>Nodal Officer Details</Box>
                <Box {...styles("container")}>
                  <Box {...styles("input")}>
                    <Input
                      name="currentAddress.district"
                      label="Nodal Officer Name"
                      placeholder="Enter Nodal Officer Name"
                      control={control}
                      value={userData?.nodalOfficerDetail?.firstName}
                      height={height}
                      readOnly
                      customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="currentAddress.district"
                      label="Designation"
                      placeholder="Enter Designation"
                      control={control}
                      value={userData?.nodalOfficerDetail?.designation}
                      height={height}
                      readOnly
                      customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="currentAddress.district"
                      label="Email "
                      placeholder="Enter Email"
                      control={control}
                      value={userData?.nodalOfficerDetail?.email}
                      height={height}
                      readOnly
                      customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="currentAddress.district"
                      label="Phone Number "
                      placeholder="Enter Phone Number"
                      control={control}
                      value={userData?.nodalOfficerDetail?.phoneNumber}
                      height={height}
                      readOnly
                      customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="currentAddress.district"
                      label="State"
                      placeholder="Enter State"
                      control={control}
                      value={userData?.nodalOfficerDetail?.state}
                      height={height}
                      readOnly
                      customStyles={{ wrapper: defaultStyles.selectWrapper }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        severity="error"
        message="Something Went Wrong"
        open={isError}
        duration={2000}
        resetSnackbar={() => setIsError(false)}
      />
    </DashboardWrapper>
  );
};

export default UserDetails;
