import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import { Input } from "../FormComponents";
import defaultStyles, { StylesClasses } from "./styles";
import { UserInfo } from "./UserInfo";

type HeaderProps = {
  customStyles?: CustomStyles<StylesClasses>;
};

const Header = ({ customStyles }: HeaderProps) => {
  const { control } = useForm();
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("headerWrapper")}>
      <Box {...styles("actionFieldWrapper")}>
        {/* <SearchBar getText={() => {}} /> */}
        <Input
          {...styles("inputSearchBar")}
          placeholder="search..."
          name="searchBar"
          control={control}
        />
        <Input
          {...styles("inputSearchBar")}
          placeholder="search..."
          name="searchBar"
          control={control}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 5 }}>
        {/* <NotificationBadge count={20} src="/assets/svg/bellIcon.svg" /> */}
        <UserInfo />
      </Box>
    </Box>
  );
};

export default Header;
