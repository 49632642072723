import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import stepper from "src/redux/slices/stepper";
import projectById from "./slices/dashboard/projectById";
import state from "./slices/state";
import user from "./slices/userDetails";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
};

const userConfig = {
  key: "user",
  storage: storage,
};

const stepperConfig = {
  key: "stepper",
  storage: storage,
};

const stateConfig = {
  key: "stateOptions",
  storage: storage,
};

const rootReducer = combineReducers({
  stepper: persistReducer(stepperConfig, stepper),
  routing: routerReducer,
  user: persistReducer(userConfig, user),
  state: persistReducer(stateConfig, state),
  projectById: projectById,
});

export type RootState = { root: ReturnType<typeof rootReducer> };

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
