import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  renderButtonText: {
    display: "block",
    textAlign: "left",
    fontSize: "17px",
  },
  deleteUser: {
    display: "block",
    textAlign: "left",
    fontSize: "14px",
    color: "red",
  },
    searchBarWrapper:{
    marginBottom: "20px",
    height:'50px'
  }
});

export default styles;
