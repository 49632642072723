import { Box, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { getStyles } from "src/styles/theme";
import { ProjectInterface } from "src/types/common";
import defaultStyles, { StylesClasses } from "../styles";

type RenderTableProps = {
  rows: {
    data: ProjectInterface;
    id: string;
    status: string;
    chipTitle: string;
    registrationNo: number;
    activityId: number;
    activityInitiationDate: string;
    totalDuration: string;
    totalCredit: number;
    areaUnit: string;
    updatedAt: string;
    remarks: string;
  }[];
  hideFooterPagination?: boolean;
  isLoading?: boolean;
  columns: any;
  rowCount?: number;
  onPageChange?: (e: number) => void;
  handleSortModelChange?: (e: any) => void;
  pageNumber?: number;
};

const RenderTable = ({
  rows,
  hideFooterPagination,
  isLoading,
  columns,
  rowCount,
  onPageChange,
  handleSortModelChange,
  pageNumber,
}: RenderTableProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <>
      {!isLoading ? (
        <>
          {rows.length ? (
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooterPagination={hideFooterPagination}
              rowCount={rowCount}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              pageSize={1}
              onChangePage={onPageChange}
              pageNumber={pageNumber}
            />
          ) : (
            <EmptyView heading={"No activities to display"} subheading={""} />
          )}
        </>
      ) : (
        <Box {...styles("flexContainer")}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default RenderTable;
